import { useEffect, useState, useInsertionEffect, useRef } from 'react';
import './FormTable.css';

import { useSelector } from 'react-redux';
import Calendar from '../../Calendar/calendar';

import ChangeRepository from './classChangeRepository';
import FormTableRepository from './classFormTableRepository';

const flagTrueFalse = <svg className="svgTrue" viewBox="12 17 32 32">
<path d="M30 19l-9 9-3-3-2 2 5 5 11-11z"></path>
</svg>;

const loading = <svg className='loading' viewBox="0 0 32 32">
<path d="M0 28h32v2h-32zM32 24v2h-32v-2l4-8h8v4h8v-4h8zM7 10l9-9 9 9h-7v8h-4v-8z"></path>
</svg>


function checkArray (arr, str) {
  for(let i = 0; i < arr.length; i++) {
    if (arr[i].VIEW_GORUNEN_ADI == str) {
      
      return [true, i];
    }
  }
  
  return [false];
}

function checkAndChangeFormTableDate (event, group, i, variableForInputValue, checkingChangeOfStore, formTableId, statusUpdate_FormTable, setstatusUpdate_FormTable ) {

  if (ChangeRepository.add[group][i].KOLON_TIPI == "TARIH_SAAT") {
    console.log('formtable time', event.target.value.length);
    if (event.target.value.length != 16) {
      variableForInputValue.current = '';
      FormTableRepository.formInputsState[formTableId] = ''
      setstatusUpdate_FormTable(!statusUpdate_FormTable);
      return;
    }
    let dataValue = String(event.target.value[0]) + String(event.target.value[1]);
    let monthValue = String(event.target.value[3]) + String(event.target.value[4]);
    let hourValue = String(event.target.value[11]) + String(event.target.value[12]);
    let minuteValue = String(event.target.value[14]) + String(event.target.value[15]);
    let checkTheNaN = event.target.value[0] + event.target.value[1] + event.target.value[3] + event.target.value[4] + event.target.value[6] + event.target.value[7] + event.target.value[8] + event.target.value[9] + event.target.value[11] + event.target.value[12] + event.target.value[14] + event.target.value[15];
    let chekingPointsFlag = event.target.value[2] == '.' && event.target.value[5] == '.' && event.target.value[10] == ' ' && event.target.value[13] == ':';
    console.log('formtable time', dataValue, monthValue, hourValue, minuteValue);
    console.log('formtable time isNaN', Number.isNaN(Number(checkTheNaN)), checkTheNaN);
    console.log('formtable time chekingPointsFlag', chekingPointsFlag);

    
    if(Number.isNaN(Number(checkTheNaN)) || 
    Number(dataValue) > 31 || Number(dataValue) < 1 ||
    Number(monthValue) > 12 || Number(monthValue) < 1 ||
    Number(hourValue) > 23 || Number(hourValue) < 0 ||
    Number(minuteValue) > 59 || Number(minuteValue) < 0 || !chekingPointsFlag) {
      variableForInputValue.current = '';
      FormTableRepository.formInputsState[formTableId] = ''
      setstatusUpdate_FormTable(!statusUpdate_FormTable);
      return;
    }
    
  } else {
    if (event.target.value.length != 10) {
      variableForInputValue.current = '';
      FormTableRepository.formInputsState[formTableId] = ''
      setstatusUpdate_FormTable(!statusUpdate_FormTable);
      return;
    }
    let dataValue = String(event.target.value[0]) + String(event.target.value[1]);
    let monthValue = String(event.target.value[3]) + String(event.target.value[4]);
    let checkTheNaN = event.target.value[0] + event.target.value[1] + event.target.value[3] + event.target.value[4] + event.target.value[6] + event.target.value[7] + event.target.value[8] + event.target.value[9];
    let chekingPointsFlag = event.target.value[2] == '.' && event.target.value[5] == '.';

    console.log('formtable time', dataValue, monthValue);
    console.log('formtable time isNaN', Number.isNaN(Number(checkTheNaN)), checkTheNaN);
    console.log('formtable time chekingPointsFlag', chekingPointsFlag);

    if(Number.isNaN(Number(checkTheNaN)) || 
    Number(dataValue) > 31 || Number(dataValue) < 1 ||
    Number(monthValue) > 12 || Number(monthValue) < 1 || !chekingPointsFlag) {
      variableForInputValue.current = '';
      FormTableRepository.formInputsState[formTableId] = ''
      setstatusUpdate_FormTable(!statusUpdate_FormTable);
      return;
    }
  }

  FormTableRepository.formInputsState[formTableId] = String(event.target.value);
  variableForInputValue.current = String(event.target.value);
  checkingChangeOfStore.current = FormTableRepository.formInputsState[formTableId];

  setstatusUpdate_FormTable(!statusUpdate_FormTable);

}


function FormTable({group, i, indicatorOfCompletionOfFields, rerenderPreFormTable, setRerenderPreFormTable, condition}) {

const [statusUpdate_FormTable, setstatusUpdate_FormTable] = useState(false);
const [statusUpdateCalendar_FormTable, setstatusUpdateCalendar_FormTable] = useState([false]);
//const [templateForPopUp, settemplateForPopUp] = useState(null);
const [checkingForEmptyField, setcheckingForEmptyField] = useState(0);

console.log('formtable indicatorOfCompletionOfFields', indicatorOfCompletionOfFields);
let formClassName = 'wrappingFormTable';
/*
if (ChangeRepository.add[group][i].BOS_OLABILIRMI == 0 && ChangeRepository.add.justWatch == false) {
  if (checkingForEmptyField === true) {
    formClassName = 'wrappingFormTable filledField';
  } else if (checkingForEmptyField === false) {
    formClassName = 'wrappingFormTable emptyField';
  }
}
*/

const formTableId = String(group)+ '_' +String(i);

if (indicatorOfCompletionOfFields === false) {
  if (ChangeRepository.add[group][i].BOS_OLABILIRMI == 0) {
    if (FormTableRepository.formInputsState[formTableId] === undefined || FormTableRepository.formInputsState[formTableId] === '') {
      formClassName = 'wrappingFormTable emptyField';
    } else {
      formClassName = 'wrappingFormTable filledField';
    }
  }
}



let flagFormTable = !(FormTableRepository.formInputsState[formTableId] !== undefined && FormTableRepository.formInputsState[formTableId] != 0 && FormTableRepository.formInputsState[formTableId] !== '');

let form;
let image;
if (ChangeRepository.add[group][i].KOLON_TIPI == "METIN" || ChangeRepository.add[group][i].KOLON_TIPI == "SAYISAL") {
  console.log('formtable Metin || Sayisal');
  let inputType = '';
  if (ChangeRepository.add[group][i].KOLON_TIPI == "METIN") {
    inputType = 'text';
  } else if (ChangeRepository.add[group][i].KOLON_TIPI == "SAYISAL") {
    inputType = 'number';
  }
  form = <div className={formClassName} onClick={() => console.log('formtable "METIN" || "SAYISAL"')}>
    <p className={`${flagFormTable ? 'activeparagraphFormTable' : 'inactiveparagraphFormTable'}`}> {ChangeRepository.add[group][i].GORUNEN_ADI } </p>
    <input placeholder={ChangeRepository.add[group][i].GORUNEN_ADI} className={!flagFormTable ? 'activeinputFormTable' : 'inactiveinputFormTable'} 
    onBlur={ function (event) {
      if (!(event.target.value === '' && FormTableRepository.formInputsState[formTableId] === '')) {
        FormTableRepository.formInputsState[formTableId] = String(event.target.value);
        setstatusUpdate_FormTable(!statusUpdate_FormTable);
        console.log('formtable number|string FormTableRepository.formInputsState', FormTableRepository.formInputsState);
      }
      /*
      if (ChangeRepository.add[group][i].BOS_OLABILIRMI == 0) {
        if (event.target.value === '') {
          setcheckingForEmptyField(false);
        } else {
          setcheckingForEmptyField(true);
        }
      }
      */
    }}
    defaultValue={FormTableRepository.formInputsState[formTableId] ?? null}
    type={inputType} 
    readOnly={condition == "KITLI"? true : false} />
  </div>;
} 


else if (ChangeRepository.add[group][i].KOLON_TIPI == "NOT") {
  console.log('formtable NOT');
  //className={`${formClassName} wrappingFormTableWithTextArea}`}
    //form = <div className={`${formClassName} wrappingFormTableWithTextArea}`}>
    //form = <div className='wrappingFormTable wrappingFormTableWithTextArea'>

  /*
  по какой то причине здесь при добавлении нового класса селектор перестает работать и 
  добавлять css стили. При этом класс, на который ориентируется селектор остается тем же. 
  */
  //let nameOfClass = `${formClassName} wrappingFormTableWithTextArea}`;
  form = <div className={formClassName}>

    <p className={`${ flagFormTable ? 'activeparagraphFormTable' : 'inactiveparagraphFormTable'}`} onClick={() => console.log(flagFormTable, FormTableRepository.formInputsState[formTableId])}> {ChangeRepository.add[group][i].GORUNEN_ADI } </p>
    <textarea placeholder={ChangeRepository.add[group][i].GORUNEN_ADI} className={!flagFormTable ? 'activeinputFormTable' : 'inactiveinputFormTable'} 
    onBlur={ function (event) {
      if (!(event.target.value === '' && FormTableRepository.formInputsState[formTableId] === '')) {
        FormTableRepository.formInputsState[formTableId] = String(event.target.value);
        setstatusUpdate_FormTable(!statusUpdate_FormTable);
        console.log('FormTable NOT FormTableRepository.formInputsState', FormTableRepository.formInputsState);
      } 
      if (event.target.value.length == 0) {
        event.target.parentNode.style.height = '64px';
        event.target.style.height = '35px';
      }
      /*
      if (ChangeRepository.add[group][i].BOS_OLABILIRMI == 0) {
        if (event.target.value === '') {
          setcheckingForEmptyField(false);
        } else {
          setcheckingForEmptyField(true);
        }
      }
      */
    }}
    defaultValue={FormTableRepository.formInputsState[formTableId] ?? null}
    onKeyDown={function (event) {
      if(event.keyCode == 13 && event.target.scrollHeight != event.target.clientHeight){
        event.target.parentNode.style.height = (event.view.document.activeElement.parentNode.offsetHeight + 24) + 'px';
        event.target.style.height = (event.view.document.activeElement.offsetHeight + 24) + 'px';
      }
    }}
    onChange={function(event) {
      if (event.target.scrollHeight != event.target.clientHeight) {
        event.target.parentNode.style.height = (event.target.scrollHeight + 48) + 'px';
        event.target.style.height = (event.target.scrollHeight + 24) + 'px';
      }
    }}
    onClick={function(event) {
      console.log('formtable NOT event', event,  event.target.style.height, event.target.scrollHeight, event.target.clientHeight);

      if (event.target.scrollHeight != event.target.clientHeight) {
        event.target.parentNode.style.height = (event.target.scrollHeight + 48) + 'px';
        event.target.style.height = (event.target.scrollHeight + 24) + 'px';
      } 
      /*
      else {
        event.target.style.height = 'auto';
      }
      */
    }}
    readOnly={condition == "KITLI"? true : false} />
    
  </div>;

useEffect(() => {
  // Код, который будет выполняться при монтировании компонента

  return () => {
    // Код, который будет выполняться при удалении компонента
    console.log('Компонент удаляется из DOM!');
    // Выполните здесь необходимые действия перед удалением компонента
  };
}, []); 
  //style={{height: `${this.scrollHeight + 48}px`}}
  /*
    useInsertionEffect(() => {
      form = <div className={formClassName}>
      <p className={`${ flagFormTable ? 'activeparagraphFormTable' : 'inactiveparagraphFormTable'}`} onClick={() => console.log(flagFormTable, FormTableRepository.formInputsState[formTableId])}> {ChangeRepository.add[group][i].GORUNEN_ADI } </p>
      <textarea placeholder={ChangeRepository.add[group][i].GORUNEN_ADI} className={!flagFormTable ? 'activeinputFormTable' : 'inactiveinputFormTable'} 
      onBlur={ function (event) {
        if (!(event.target.value === '' && FormTableRepository.formInputsState[formTableId] === '')) {
          FormTableRepository.formInputsState[formTableId] = String(event.target.value);
          setstatusUpdate_FormTable(!statusUpdate_FormTable);
          console.log('FormTable NOT FormTableRepository.formInputsState', FormTableRepository.formInputsState);
        } 
        if (event.target.value.length == 0) {
          event.target.parentNode.style.height = '64px';
          event.target.style.height = '35px';
        }
      }}
      defaultValue={FormTableRepository.formInputsState[formTableId] ?? null}
      onKeyDown={function (event) {
        if(event.keyCode == 13 && event.target.scrollHeight != event.target.clientHeight){
          event.target.parentNode.style.height = (event.view.document.activeElement.parentNode.offsetHeight + 24) + 'px';
          event.target.style.height = (event.view.document.activeElement.offsetHeight + 24) + 'px';
        }
      }}
      onChange={function(event) {
        if (event.target.scrollHeight != event.target.clientHeight) {
          event.target.parentNode.style.height = (event.target.scrollHeight + 48) + 'px';
          event.target.style.height = (event.target.scrollHeight + 24) + 'px';
        }
      }}
      />
      
    </div>;
    }, []);
    */ 
} 

else if (ChangeRepository.add[group][i].KOLON_TIPI == "TARIH_SAAT" || ChangeRepository.add[group][i].KOLON_TIPI == "TARIH") {
  
  const [timeElementUpdate_FormTable, settimeElementUpdate_FormTable] = useState(false);
  let variableForInputValue = useRef(FormTableRepository.formInputsState[formTableId] ?? null);
  let checkingChangeOfStore = useRef(FormTableRepository.formInputsState[formTableId] ?? null);

  if (checkingChangeOfStore.current != FormTableRepository.formInputsState[formTableId]) {
    variableForInputValue.current = FormTableRepository.formInputsState[formTableId];
    checkingChangeOfStore.current = FormTableRepository.formInputsState[formTableId];
  }

  if (indicatorOfCompletionOfFields === false && ChangeRepository.add[group][i].BOS_OLABILIRMI == 0) {
    if (FormTableRepository.formInputsState[formTableId] == 0 || FormTableRepository.formInputsState[formTableId] == undefined || FormTableRepository.formInputsState[formTableId] == '') {
      formClassName = 'wrappingFormTable emptyField';
    } else {
      formClassName = 'wrappingFormTable filledField';
    } 
  }

  form = <> <div className={formClassName} onBlur={function(event) {
    /*
    if (ChangeRepository.add[group][i].BOS_OLABILIRMI == 0) {
        if (variable != 0) {
          if (FormTableRepository.formInputsState[formTableId] === 0 || FormTableRepository.formInputsState[formTableId] === undefined) {
            setcheckingForEmptyField(false);
          } else if (FormTableRepository.formInputsState[formTableId] != 0 && FormTableRepository.formInputsState[formTableId] != undefined) {
            setcheckingForEmptyField(true);
          } 
        }
        else {
          variable = 1;
        }
    }
    */
    
    console.log('formtable TARIH event.target.defaultValue', event.target.defaultValue);
    console.log('formtable TARIH event', event);

    console.log('formtable TARIH FormTableRepository.formInputsState[formTableId]', FormTableRepository.formInputsState[formTableId]);

  }}>
    <p className={`${ flagFormTable ? 'activeparagraphFormTable' : 'inactiveparagraphFormTable'}`} onClick={() => console.log(flagFormTable, FormTableRepository.formInputsState[formTableId])}> {ChangeRepository.add[group][i].GORUNEN_ADI } </p>
    <input placeholder={ChangeRepository.add[group][i].GORUNEN_ADI} className={!flagFormTable ? 'activeinputFormTable colorInputChild2' : 'inactiveinputFormTable colorInputChild2'} 
    value={variableForInputValue.current}
    onChange={function (event) {
      
      if (typeof variableForInputValue.current == 'string' && 
      event.target.value.length > variableForInputValue.current.length) {
        if (ChangeRepository.add[group][i].KOLON_TIPI == "TARIH_SAAT") {
          switch (event.target.value.length) {
            case 2: 
              event.target.value+= '.';
            break;
            case 5: 
              event.target.value+= '.';
            break;
            case 10: 
              event.target.value+= ' ';
            break;
            case 13: 
              event.target.value+= ':';
            break;
          }
        } else {
          switch (event.target.value.length) {
            case 2: 
              event.target.value+= '.';
            break;
            case 5: 
              event.target.value+= '.';
            break;
          }
        }
      }
      variableForInputValue.current = event.target.value;
      setstatusUpdate_FormTable(!statusUpdate_FormTable);
    }}
    onBlur={ function (event) {
      if (!(event.target.value === '' && FormTableRepository.formInputsState[formTableId] === '')) {
        /*
        if (ChangeRepository.add[group][i].KOLON_TIPI == "TARIH_SAAT") {
          console.log('formtable time', event.target.value.length);
          if (event.target.value.length != 16) {
            variableForInputValue.current = '';
            FormTableRepository.formInputsState[formTableId] = ''
            setstatusUpdate_FormTable(!statusUpdate_FormTable);
            return;
          }
          let dataValue = String(event.target.value[0]) + String(event.target.value[1]);
          let monthValue = String(event.target.value[3]) + String(event.target.value[4]);
          let hourValue = String(event.target.value[11]) + String(event.target.value[12]);
          let minuteValue = String(event.target.value[14]) + String(event.target.value[15]);
          let checkTheNaN = event.target.value[0] + event.target.value[1] + event.target.value[3] + event.target.value[4] + event.target.value[6] + event.target.value[7] + event.target.value[8] + event.target.value[9] + event.target.value[11] + event.target.value[12] + event.target.value[14] + event.target.value[15];
          let chekingPointsFlag = event.target.value[2] == '.' && event.target.value[5] == '.' && event.target.value[10] == ' ' && event.target.value[13] == ':';
          console.log('formtable time', dataValue, monthValue, hourValue, minuteValue);
          console.log('formtable time isNaN', Number.isNaN(Number(checkTheNaN)), checkTheNaN);
          console.log('formtable time chekingPointsFlag', chekingPointsFlag);

          
          if(Number.isNaN(Number(checkTheNaN)) || 
          Number(dataValue) > 31 || Number(dataValue) < 1 ||
          Number(monthValue) > 12 || Number(monthValue) < 1 ||
          Number(hourValue) > 23 || Number(hourValue) < 0 ||
          Number(minuteValue) > 59 || Number(minuteValue) < 0 || !chekingPointsFlag) {
            variableForInputValue.current = '';
            FormTableRepository.formInputsState[formTableId] = ''
            setstatusUpdate_FormTable(!statusUpdate_FormTable);
            return;
          }
          
        } else {
          if (event.target.value.length != 10) {
            variableForInputValue.current = '';
            FormTableRepository.formInputsState[formTableId] = ''
            setstatusUpdate_FormTable(!statusUpdate_FormTable);
            return;
          }
          let dataValue = String(event.target.value[0]) + String(event.target.value[1]);
          let monthValue = String(event.target.value[3]) + String(event.target.value[4]);
          let checkTheNaN = event.target.value[0] + event.target.value[1] + event.target.value[3] + event.target.value[4] + event.target.value[6] + event.target.value[7] + event.target.value[8] + event.target.value[9];
          let chekingPointsFlag = event.target.value[2] == '.' && event.target.value[5] == '.';

          console.log('formtable time', dataValue, monthValue);
          console.log('formtable time isNaN', Number.isNaN(Number(checkTheNaN)), checkTheNaN);
          console.log('formtable time chekingPointsFlag', chekingPointsFlag);

          if(Number.isNaN(Number(checkTheNaN)) || 
          Number(dataValue) > 31 || Number(dataValue) < 1 ||
          Number(monthValue) > 12 || Number(monthValue) < 1 || !chekingPointsFlag) {
            variableForInputValue.current = '';
            FormTableRepository.formInputsState[formTableId] = ''
            setstatusUpdate_FormTable(!statusUpdate_FormTable);
            return;
          }
        }

        FormTableRepository.formInputsState[formTableId] = String(event.target.value);
        variableForInputValue.current = String(event.target.value);
        checkingChangeOfStore.current = FormTableRepository.formInputsState[formTableId];

        setstatusUpdate_FormTable(!statusUpdate_FormTable);
        */

        checkAndChangeFormTableDate (event, group, i, variableForInputValue, checkingChangeOfStore, formTableId, statusUpdate_FormTable, setstatusUpdate_FormTable );
      }
    }}

    onKeyDown={ function (event) {
      if (event.target.value != FormTableRepository.formInputsState[formTableId] && event.keyCode == 13) {
        
        checkAndChangeFormTableDate (event, group, i, variableForInputValue, checkingChangeOfStore, formTableId, statusUpdate_FormTable, setstatusUpdate_FormTable );
      }
    }}
    readOnly={condition == "KITLI"? true : false}
    maxLength={ChangeRepository.add[group][i].KOLON_TIPI == "TARIH_SAAT" ? 16 : 10}
    />
  </div>
  <button className='button_calendar_formtable' onClick={() => settimeElementUpdate_FormTable(!timeElementUpdate_FormTable) }>calendar</button>
  <Calendar state={0} setState={setstatusUpdateCalendar_FormTable} index={0} name={formTableId} repository={'FormTable'} placeholderData={ChangeRepository.add[group][i].GORUNEN_ADI} defaultValue={FormTableRepository.formInputsState[formTableId] ?? 0} propTimepicker={ChangeRepository.add[group][i].KOLON_TIPI == "TARIH_SAAT" ? true : false} statusOpen={(timeElementUpdate_FormTable && condition != "KITLI") ? true : false} />
  
  </>;
} 

else if (ChangeRepository.add[group][i].KOLON_TIPI == "ACILAN_KUTU") {
  const [templateForPopUp, settemplateForPopUp] = useState(null);
  const regexpForPopUp = new RegExp(`${(templateForPopUp ?? '')}`);
  //const defaultValueDropWindow = ChangeRepository.add[group][i].VIEW_DATA[FormTableRepository.formInputsState[formTableId] - 1]?.VIEW_GORUNEN_ADI?? FormTableRepository.formInputsState[formTableId]?? null;
  //const defaultValueDropWindow = ChangeRepository.add[group][i].VIEW_DATA[Number(FormTableRepository.formInputsState[formTableId]) - 1]?.VIEW_GORUNEN_ADI?? (FormTableRepository.formInputsState[formTableId] == 0 || FormTableRepository.formInputsState[formTableId] == undefined) ? null : FormTableRepository.formInputsState[formTableId];
  let defaultValueDropWindow;
  if (ChangeRepository.add[group][i].VIEW_DATA[FormTableRepository.formInputsState[formTableId] - 1]?.VIEW_GORUNEN_ADI !== undefined) {
    defaultValueDropWindow = ChangeRepository.add[group][i].VIEW_DATA[FormTableRepository.formInputsState[formTableId] - 1]?.VIEW_GORUNEN_ADI;
  } else if (FormTableRepository.formInputsState[formTableId] != 0 && FormTableRepository.formInputsState[formTableId] != undefined) {
    defaultValueDropWindow = FormTableRepository.formInputsState[formTableId];
  } else {
    defaultValueDropWindow = "";
  }
  console.log('formTable ACILAN_KUTU defaultValueDropWindow', defaultValueDropWindow, FormTableRepository.formInputsState[formTableId], ChangeRepository.add[group][i].VIEW_DATA[Number(FormTableRepository.formInputsState[formTableId]) - 1]?.VIEW_GORUNEN_ADI);

  form = <div className={formClassName} onClick={() => console.log('ACILAN_KUTU', ChangeRepository.add[group][i].VIEW_DATA[FormTableRepository.formInputsState[formTableId] - 1]?.VIEW_GORUNEN_ADI)} onBlur={() => console.log('ACILAN_KUTU', FormTableRepository.formInputsState)}>
    <p className={`${ flagFormTable ? 'activeparagraphFormTable' : 'inactiveparagraphFormTable'}`} > {ChangeRepository.add[group][i].GORUNEN_ADI } </p>
    <input placeholder={`${ChangeRepository.add[group][i].GORUNEN_ADI}`} className={`${!flagFormTable ? 'activeinputFormTable' : 'inactiveinputFormTable'}`} value={templateForPopUp ?? defaultValueDropWindow} 
        onChange={function(event) {
          if (condition != "KITLI") {
            settemplateForPopUp (event.target.value);
          }
        }} 
        onBlur={ function (event) {
          if (condition != "KITLI") {
            const checkArrayVariable = checkArray (ChangeRepository.add[group][i].VIEW_DATA, event.target.value);
            console.log(checkArrayVariable);
            if (!(event.target.value === '' && FormTableRepository.formInputsState[formTableId] === '') && checkArrayVariable[0]) {
              //FormTableRepository.formInputsState[formTableId] = ChangeRepository.add[group][i].VIEW_DATA[checkArrayVariable[1]].VIEW_DEGER_ADI;
              if (FormTableRepository.formInputsState[formTableId] != ChangeRepository.add[group][i].VIEW_DATA[checkArrayVariable[1]].VIEW_DEGER_ADI 
                && setRerenderPreFormTable != false) {
                setRerenderPreFormTable([ChangeRepository.add[group][i].ID, ChangeRepository.add[group][i].VIEW_DATA[checkArrayVariable[1]].VIEW_DEGER_ADI]);
              }
              FormTableRepository.formInputsState[formTableId] = ChangeRepository.add[group][i].VIEW_DATA[checkArrayVariable[1]].VIEW_DEGER_ADI;
              setstatusUpdate_FormTable(!statusUpdate_FormTable);
            } else {
              FormTableRepository.formInputsState[formTableId] = '';
              //setstatusUpdate_FormTable(!statusUpdate_FormTable);
              settemplateForPopUp('');
              setstatusUpdate_FormTable(!statusUpdate_FormTable);

            }
            console.log('formtable ACILAN_KUTU FormTableRepository.formInputsState[formTableId]', FormTableRepository.formInputsState[formTableId] === '');
          }
          /*
          if (ChangeRepository.add[group][i].BOS_OLABILIRMI == 0) {
            if (event.target.value === '') {
              setcheckingForEmptyField(false);
            } else {
              setcheckingForEmptyField(true);
            }
          }
          */
        }}
        readOnly={condition == "KITLI"? true : false}/>
    <div className={`popUpWindow_FormTable`}>
    {templateForPopUp == '' && ChangeRepository.add[group][i].VIEW_DATA.map(item => <div 
    onMouseDown={function(event) {
      if (condition != "KITLI") {
        settemplateForPopUp(item.VIEW_GORUNEN_ADI);
      }
    }}>{item.VIEW_GORUNEN_ADI}</div>)}
    {templateForPopUp != '' && ChangeRepository.add[group][i].VIEW_DATA.filter(item => (regexpForPopUp.test((item.VIEW_GORUNEN_ADI)))).map(item => <div 
    onMouseDown={function(event) {
      if (condition != "KITLI") {
        settemplateForPopUp(item.VIEW_GORUNEN_ADI); 
      }
    }}>{item.VIEW_GORUNEN_ADI}</div>)}
    </div>
  </div>;
} 

else if (ChangeRepository.add[group][i].KOLON_TIPI == "ONAY_KUTUSU") {
  formClassName = 'wrappingFormTable';
  FormTableRepository.formInputsState[formTableId] == '0'
  form = <div className={formClassName}>
    <div className={`flagTrueFalse`} onClick={function () {
      if (condition != "KITLI") {
        if (FormTableRepository.formInputsState[formTableId] == undefined || FormTableRepository.formInputsState[formTableId] == '0') {
          FormTableRepository.formInputsState[formTableId] = '1';
          setstatusUpdate_FormTable(!statusUpdate_FormTable);
        } else if (FormTableRepository.formInputsState[formTableId] == '1') {
          FormTableRepository.formInputsState[formTableId] = '0';
          setstatusUpdate_FormTable(!statusUpdate_FormTable);
        } 
      }
  }}>
      <p> {ChangeRepository.add[group][i].GORUNEN_ADI } </p>
      <div>{FormTableRepository.formInputsState[formTableId] == '1' && flagTrueFalse}</div>
    </div>
  </div>;
} 

else if (ChangeRepository.add[group][i].KOLON_TIPI == "DOSYA_RESIM" || ChangeRepository.add[group][i].KOLON_TIPI == "DOSYA_VIDEO" || ChangeRepository.add[group][i].KOLON_TIPI == "DOSYA_PDF") {
  //console.log('formtable ChangeRepository.add[group][i].KOLON_DEGER', ChangeRepository.add[group][i].KOLON_DEGER);
  let inputType = '';
  const [valueForInputType, setvalueForInputType] = useState([ChangeRepository.add[group][i].GORUNEN_ADI, 0]);

  if (ChangeRepository.add[group][i].KOLON_TIPI == "DOSYA_RESIM") {
    inputType = "image/*";
  } else if (ChangeRepository.add[group][i].KOLON_TIPI == "DOSYA_VIDEO") {
    inputType = "video/*";
  }
  else if (ChangeRepository.add[group][i].KOLON_TIPI == "DOSYA_PDF") {
    inputType = ".pdf";
  }

  console.log('formtable DOSYA_RESIM valueForInputType', valueForInputType);
  let base64Image = `data:image/png;base64,${ChangeRepository.add[group][i].KOLON_DEGER}`;
  console.log('formtable DOSYA image', image);
  if (ChangeRepository.add[group][i].KOLON_DEGER != null || !flagFormTable) {
    if (ChangeRepository.add[group][i].KOLON_TIPI == "DOSYA_RESIM") {
      image = <div className='imageWrapping_formtable'>
      <img className='imagePng_formtable' src={`data:image/png;base64,${valueForInputType[1] == 0 ? ChangeRepository.add[group][i].KOLON_DEGER : FormTableRepository.formInputsState[formTableId]}`} 
        onDoubleClick={function(event) {
          //console.log('formtable DOSYA_RESIM', event.target);
          //window.open('data:image/png;base64,' + ChangeRepository.add[group][i].KOLON_DEGER, "_blank");
         

          
          /*
          let variableForWindow = window.open();
          variableForWindow.document.open();
          variableForWindow.document.append(event.target);
          variableForWindow.document.close();
          //этот код не просто добавляет элемент на другую страницу, но также удаляет его и с этой страницы
          */

        }}/>
      </div>;
      console.log('formtable DOSYA_PDF image', image);
    } else if (ChangeRepository.add[group][i].KOLON_TIPI == "DOSYA_PDF") {
      /*
      image = <div className='imageWrapping_formtable'> 
        <iframe className='iapplicationPdf_formtable' src={`data:application/pdf;base64,${ChangeRepository.add[group][i].KOLON_DEGER}`} 
          onDoubleClick={function(event) {
            console.log('formtable DOSYA_RESIM', event.target);
            let variableForWindow = window.open();
            variableForWindow.document.open();
            variableForWindow.document.append(event.target);
            variableForWindow.document.close();
          }}/>
      </div>;
      */
      const [stateOfIframe, setstateOfIframe] = useState(false);

      image = <><div className='imageWrapping_formtable' style={{height: stateOfIframe ? '80vh' : '0px'}}>
      <iframe className='iapplicationPdf_formtable' src={`data:application/pdf;base64,${ChangeRepository.add[group][i].KOLON_DEGER}`} sandbox
      style={{display: stateOfIframe ? 'block' : 'none'}}/>
      </div>
      <button onClick={() => setstateOfIframe(!stateOfIframe)} value={stateOfIframe ? 'kapalı' : 'açık'}
      className='button_iapplicationPdf_formtable'>{stateOfIframe ? 'kapalı' : 'açık'}</button>
      </>
    }
  }

  form = <div className={`${formClassName} ${ChangeRepository.add[group][i]}`}>
    <p className={`${flagFormTable ? 'activeparagraphFormTable' : 'inactiveparagraphFormTable'}`}> {ChangeRepository.add[group][i].GORUNEN_ADI } </p>
    <label className={!flagFormTable ? 'activeinputFormTable' : 'inactiveinputFormTable'}>
      <input placeholder={ChangeRepository.add[group][i].GORUNEN_ADI}  
      onChange={ function (event) {
        if (condition != "KITLI") {
          if (event.target?.files?.[0]?.name != undefined) {
            //let blob = new Blob(['Hello, world!'], {type: 'text/plain'});
            if (ChangeRepository.add.justWatch == false) {
              let blob = new Blob([event.target?.files?.[0]]);
              console.log('formtable DOSYA_RESIM blob', blob);
    
              let reader = new FileReader();
              reader.readAsDataURL(blob); // конвертирует Blob в base64 и вызывает onload
    
              reader.onload = function() {
                console.log('formtable DOSYA_RESIM reader.result', typeof reader.result);
                console.log('formtable DOSYA_RESIM reader.result', reader.result)
    
                let result = reader.result.split('base64,');
                FormTableRepository.formInputsState[formTableId] = result[1];
                console.log('formtable DOSYA_RESIM reader.result', result[1]);
                console.log('formtable DOSYA_RESIM valueForInputType', valueForInputType);

                setvalueForInputType([event.target.files[0].name, 1]);
              };
              reader.onerror = function() {
                console.log(reader.error);
                setvalueForInputType([event.target.files[0].name, 0]);
              };
            }
  
  
            //FormTableRepository.formInputsState[formTableId] = String(event.target.value);
            //setstatusUpdate_FormTable(!statusUpdate_FormTable);
            //setvalueForInputType(event.target.files[0].name);
          }
        }

        console.log('formtable DOSYA_RESIM event', event);
      }}

      type="file" 
      accept={inputType} 
      className='downloadInput'/>
      <div>{valueForInputType[0]}</div>
      <div className={`buttonForFileInput ${flagFormTable ? 'shiftY30' : 'shiftY10'}`}>dosya yükleme</div>
    </label>


    
  </div>;

  /*
  var picture = new Image();
  picture.src = base64Image;
  document.getElementById('test').appendChild(man);
  */
} 

else if (ChangeRepository.add[group][i].KOLON_TIPI == "COLOR_PICKER") {


  form = <div className='wrappingFormTable colorInput'>
    <p className={`${flagFormTable ? 'activeparagraphFormTable' : 'inactiveparagraphFormTable'}`}> {ChangeRepository.add[group][i].GORUNEN_ADI } </p>
    <div className='colorInputDiv'>
      <div  className={!flagFormTable ? 'activeinputFormTable frameForColorInput' : 'inactiveinputFormTable frameForColorInput'}>
        <input type="color" className={'colorInputChild1'} 
          onChange={ function (event) {
            if (condition != "KITLI") {
              FormTableRepository.formInputsState[formTableId] = String(event.target.value);
              setstatusUpdate_FormTable(!statusUpdate_FormTable);
            }
          }}
          defaultValue={FormTableRepository.formInputsState[formTableId] ?? null}/>
      </div>
    <input placeholder={ChangeRepository.add[group][i].GORUNEN_ADI} className={!flagFormTable ? 'activeinputFormTable colorInputChild2' : 'inactiveinputFormTable colorInputChild2'} 
    value={FormTableRepository.formInputsState[formTableId] ?? null}
    readOnly
    />
    </div>
  </div>;
} 

else {
  form = <div className={formClassName} onClick={() => console.log('formtable ChangeRepository.add[group][i].KOLON_TIPI', ChangeRepository.add[group][i].KOLON_TIPI)}>
    <p className={`${flagFormTable ? 'activeparagraphFormTable' : 'inactiveparagraphFormTable'}`}> {ChangeRepository.add[group][i].GORUNEN_ADI } </p>
    <input placeholder={ChangeRepository.add[group][i].GORUNEN_ADI} className={!flagFormTable ? 'activeinputFormTable' : 'inactiveinputFormTable'} 
    onBlur={ function (event) {
      if (!(event.target.value === '' && FormTableRepository.formInputsState[formTableId] === '')) {
        FormTableRepository.formInputsState[formTableId] = String(event.target.value);
        setstatusUpdate_FormTable(!statusUpdate_FormTable);
        console.log(FormTableRepository.formInputsState);
      }
      /*
      if (ChangeRepository.add[group][i].BOS_OLABILIRMI == 0) {
        if (event.target.value === '') {
          setcheckingForEmptyField(false);
        } else {
          setcheckingForEmptyField(true);
        }
      }
      */
    }}
    defaultValue={FormTableRepository.formInputsState[formTableId] ?? null}
    onClick={() => console.log(!flagFormTable)}/>
  </div>;
}


return <>
  
  {form}
  {image}
  

</>;
}

export default FormTable;


//      {(ChangeRepository.add[group][i].VIEW_DATA).filter(item => (regexpForPopUp.test((item.VIEW_GORUNEN_ADI).toLowerCase()))).map(item => <div onMouseDown={(event) => settemplateForPopUp (event) }>{item.VIEW_GORUNEN_ADI}</div>))}
//        defaultValue={ChangeRepository.add[group][i].VIEW_DATA[FormTableRepository.formInputsState[formTableId] - 1]?.VIEW_GORUNEN_ADI ?? null}

/*
Проблема сделать такое поле ввода, которое будет увеличивать высоту при введении текста.
1. сделать тег textarea. Недостаток - не увеличивается в высоту при заполнении.
2. сделать тег div с атрибутом contentEditable={true}. Уже гораздо лучше, однако 
по прежнему работает неправильно. Но контейнер не увеличиваются, хотя при этом сам div увеличивается и
нижний контейнер опускается и освобождает место. 
В этом случае div не увеличивается, а просто дублируется.  
3. сделать state и обновлять при нажатии кнопки enter добавляя высоту. Недостаток - что делать 
с переполнением по горизонтали, как автоматически переводить строку вниз? 
*/
//        event.style.height = (event.style.height + 64) + "px";
//        event.view.document.activeElement.attributes.rows.value++;
// event.relatedTarget

/*
Нужно чтобы при превышении разницы между event.target.scrollHeight и event.target.clientHeight более 
чем на 27px к event.target.style.height добавлялось значение event.target.clientHeight или event.target.scrollHeight.
Однако по какой то причине условие постоянно выполняется и поле увеличивается.

Чтобы не дергался текст достаточно просто все время иметь свободное место с запасом. 

*/

/*
Нет хороших решений этой проблемы.
Нужно добавить символ переноса в textarea, чтобы всегда был запас? 
Увы, единственный вариант это увеличивать высоту с увеличением количества строк текста, 
однако посчитать количество строк текста невозможно.

на 1 строке умещается максимум 83 символа

Нужно посмотреть на решение с помощью div с атрибутом contenteditable='true'.
В таком случае можно узнать количество строк и увеличить родительский элемент.
Но нужно будет убрать у div отступы, так как при переходе на следующую строку пояляется новый div.
*/

/*
нужно чтобы каким то образом делался запас высоты для textarea. Как его сделать?
Я сделал переменную, которая хранила предыдущую высоту, однако это не помогло полностью. 
Все равно были рывки при переполнении. 
Нужно каким то образом узнавать о количестве свободного места в textarea. 
Подсчитать строки нельзя, подсчет количества символов бесполезен, потому что там могут быть символы 
переноса. Вставка каких либо элементов в начало или конец textarea также бесполезны, потому что 
это не отменит эффект переполнения.
Также не удается заставить текст оставаться на месте во время переполнения. Тег сначала прокручивается 
вниз, а потом вверх. 

Может сделать очень большой textarea, но скрыть его под родительским div?
В таком случае нет возможности впоймать событие переполнения и непонятно насколько нужно увеличивать 
родительский div. К тому же в firefox может съехать верстка. 

Подсчитать место, которое занимает текст невозможно, так как это значение получается в дереве 
отображения браузера. 



*/
// devicePixelRatio есть параметр в view или window

/*
Задача: 
Во время открытия форм для изменения таблицы нужно вывести картинку, видео и pdf. 

Проблема: основная проблема здесь в нагрузке на сеть. Довольно сложно по каждому запросу выдавать 
хотя бы картинку, а тем более видео. Значит нужно сохранять ресурсы в памяти компьютера. 
Однако и здесь есть проблемы - браузер не позволит записать текст в компьютер, а хранить 
данные в оперативной памяти плохая идея. Может использовать indexedDB? Это подошло бы, однако 
эта база данных не работает.  

База данных вроде работает, однако все ее методы асинхронные. Это создает огромную проблему 
так как единственная возможность нормально с ней работать - это использовать цепочки промисов. 
Впрочем возможно удастся сделать простые запросы в react без промисов. 

*/


/*
Удалось загрузить картинку в браузер. 
Теперь нужно:
1. разграничить случаи когда данные есть и когда их нет
2. разграничить картинки и pdf файлы

нужно чтобы элемент нормально отображался и имел css стили

какие размеры доолжны быть у рисунка?

*/

/*
Как сделать чтобы textarea был по высоте равен своему тексту?
1. можно при монтировании компонента установить стиль для html элемента. Однако 
здесь проблема, как узнать какую высоту установить для элемента? This здесь не работает. 
2. можно привязать код к событию DOMContentLoaded event, однако непонятно как найти именно 
нужный элемент. 

как можно у элемента найти высоту? Через dom. А как найти элемент в dom?
Может установить элементу специальный класс? 
Можно ли как нибудь уменьшить размер textarea, если размер текста в нем уменьшится?
Может использовать стиль height: auto? 
Но как понять, что размер уменьшился?
Нельзя уменьшить размер textarea по его содержимому, свойство height: auto уменьшает размер поля 
просто до размера по умолчанию. Также в dom нет никакого указания на высоту текста в textarea. Это 
работает только если есть переполнение поля. 

Итак, размер текста нельзя узнать никак. Однако можно создать 2 элемента, один textaerea, а 
другой div или span. Второй элемент вынести за пределы экрана. Далее вставлять текст в оба элемента, 
после чего замерять высоту второго элемента и устанавливать такую же высоту первому элементу. 

*/

/*

<symbol id="icon-upload2" viewBox="0 0 32 32">
<path d="M0 28h32v2h-32zM32 24v2h-32v-2l4-8h8v4h8v-4h8zM7 10l9-9 9 9h-7v8h-4v-8z"></path>
</symbol>
*/

/*
Как стилизовать input для загрузки рисунков?
можно использовать lable и в него вложить инпут и div. Инпут скрыть, а div стилизовать. 
При клике на div, событие будет всплывать к lable, а потом перейдет к инпут. 
Как показать какой файл был выбран? 
делаем state, потом при событии onchange в инпуте в state записываем название файла. 
Потом компонент обновляется и в div запишется новое значение state. 
*/

/*
      <input placeholder={ChangeRepository.add[group][i].GORUNEN_ADI} className={!flagFormTable ? 'activeinputFormTable downloadInput' : 'inactiveinputFormTable downloadInput'} 
      onBlur={ function (event) {
        if (!(event.target.value === '' && FormTableRepository.formInputsState[formTableId] === '')) {
          FormTableRepository.formInputsState[formTableId] = String(event.target.value);
          setstatusUpdate_FormTable(!statusUpdate_FormTable);
          console.log(FormTableRepository.formInputsState);
        }
        console.log('formtable DOSYA_RESIM event', event);
      }}
*/


//const reader = new FileReader();
//readAsDataURL(blob) – считать данные как base64-кодированный URL.


//URL.createObjectURL
//typedArrayToURL()

/*
Задача: нужно получить файл png или pdf и закодировать его в base64.
Решение: нужно использовать объекты Blob и FileReader. Объект blob получает сам файл, а 
метод readAsDataURL(blob) у объекта filereader преобразует содержимое файла в строку с 
кодировкой base64. 

Алгоритм: 
1. при срабатывании события change в теге input код находит данные о выбранном объекте.
2. далее создается объект blob, который и получает сам файл. 
3. далее создается объект FileReader.
4. далее у объекта FileReader используется метод readAsDataURL(). Этот метод является асинхронным,
и в случае успешного выполнения вызывается метод onload, а в случае ошибки вызывается метод onerror.
5. далее в методе onload разделяем получившуюся строку на метаданные и непосредственно сам файл с 
помощью метода split. Потом записываем данные файла в FormTableRepository.formInputsState[formTableId].

*/

/*
Задача: нужно чтобы при выборе новой картинки, эта картинка загружалась на страницу еще до того, 
как картинка отправится на сервер. Также нужно добавить функцию очистки памяти.

Решение: нужно сделать state для обновления состояния, или использовать уже имеющийся.
В state можно записать изначально 0, а потом записывать например [0]. Если state равен 0, 
то данные будут браться из хранилища ChangeRepository.add, а если не равен, то данные будут 
браться из FormTableRepository.formInputsState. В случае обновления компонента, данные автоматически 
будут взяты из нового источника. 


*/

/*
Нужно сдклать так, чтобы пользователь мог вводить дату в календарь вручную. 
Как это совместить с выводимым календарем?
можно сделать 2 инпута, в первом будет отображаться информация и можно будет добавлять данные
вручную, а второй будет выводить календарь. 

input можно отвязать от календаря, и поставить его отдельно, а календарь сделать 
всплывающим по нажатию кнопки. К тому же можно можно в input добавить регулярное выражение, 
чтобы пользователи правильно вводили даты. 

Нужно сделать так, чтобы в input можно было вводить свои значения, и для этого нужно отвязать 
атрибут value от значения в хранилище. 
Нужно оставить возможность изменять данные в input при изменении данных в календаре, 
а для этого атрибут value должен быть привязан к значению в хранилище. 
Как эти вещи совместить?
Обычно в таких случаях берут state и value у input привязывают к значению в state.
При срабатывании события onChange значение в state меняется. То есть браузер определяет 
что должно быть в input при каждом изменении его содержимого? Что если поступить также 
с хранилищем? А чтобы оно не переписывалось каждый раз можно сделать специальную переменную в самом компоненте. 
Получилось, но пришлось использовать useRef.
Далее нужно сделать проверку на правильное написание даты и времени. 
Атрибут pattern не пойдет, он ничего не сделает.
Нужно:
1. сделать маску, которая будет помогать вводить данные.
2. сделать проверку на корректность вводимых данных. 

Может делать итерации, чтобы добавить нужные символы и проверить правильность данных?
Итак, все сделано. Однако теперь данные берутся из переменной в компоненте, а не из хранилища. 
Как можно понять, что данные в хранилище изменились, и взять оттуда данные?
Сделаем новый useRef, в котором будет лежать значение из хранилища. 
Если изменения будут происходить в самом компоненте, то при каждом изменении данных в 
хранилище будем изменять и данные в useRef. 
Однако если изменения в хранилище будут сделаны из календаря, то useRef уже не будет равен 
значению в хранилище. Тогда мы приравниваем переменную в компоненте к значению в хранилище, 
и так же делаем с useRef.
Это сработало.

Данные в хранилище для полей записывается в функции inputsWrapping в компоненте preFormTable.
*/
