import { useEffect, useState, useInsertionEffect, useRef } from 'react';

import { useSelector } from 'react-redux';
//import Calendar from '../../Calendar/calendarForForms';
import Calendar from './calendarForForms';

import DinamikRaporRepository from './classDinamikRaporRepository';

import StoreRequest from '../formComponent/classStoreRequestForm';


const flagTrueFalse = <svg className="svgTrue" viewBox="12 17 32 32">
<path d="M30 19l-9 9-3-3-2 2 5 5 11-11z"></path>
</svg>;

const loading = <svg className='loading' viewBox="0 0 32 32">
<path d="M0 28h32v2h-32zM32 24v2h-32v-2l4-8h8v4h8v-4h8zM7 10l9-9 9 9h-7v8h-4v-8z"></path>
</svg>


function checkArray (arr, str) {
  for(let i = 0; i < arr.length; i++) {
    if (arr[i].VIEW_GORUNEN_ADI == str) {
      
      return [true, i];
    }
  }
  
  return [false];
}

function checkAndChangeFormTableDate (event, id, index, variableForInputValue, checkingChangeOfStore, statusUpdate_FormTable, setstatusUpdate_FormTable ) {

  if (StoreRequest.store[id]?.secondFormList[index]?.KOLON_TIPI == "TARIH_SAAT") {
    console.log('formtable time', event.target.value.length);
    if (event.target.value.length != 16) {
      variableForInputValue.current = '';
      DinamikRaporRepository.secondFormInputsState[index] = ''
      setstatusUpdate_FormTable(!statusUpdate_FormTable);
      return;
    }
    let dataValue = String(event.target.value[0]) + String(event.target.value[1]);
    let monthValue = String(event.target.value[3]) + String(event.target.value[4]);
    let hourValue = String(event.target.value[11]) + String(event.target.value[12]);
    let minuteValue = String(event.target.value[14]) + String(event.target.value[15]);
    let checkTheNaN = event.target.value[0] + event.target.value[1] + event.target.value[3] + event.target.value[4] + event.target.value[6] + event.target.value[7] + event.target.value[8] + event.target.value[9] + event.target.value[11] + event.target.value[12] + event.target.value[14] + event.target.value[15];
    let chekingPointsFlag = event.target.value[2] == '.' && event.target.value[5] == '.' && event.target.value[10] == ' ' && event.target.value[13] == ':';
    console.log('formtable time', dataValue, monthValue, hourValue, minuteValue);
    console.log('formtable time isNaN', Number.isNaN(Number(checkTheNaN)), checkTheNaN);
    console.log('formtable time chekingPointsFlag', chekingPointsFlag);

    
    if(Number.isNaN(Number(checkTheNaN)) || 
    Number(dataValue) > 31 || Number(dataValue) < 1 ||
    Number(monthValue) > 12 || Number(monthValue) < 1 ||
    Number(hourValue) > 23 || Number(hourValue) < 0 ||
    Number(minuteValue) > 59 || Number(minuteValue) < 0 || !chekingPointsFlag) {
      variableForInputValue.current = '';
      DinamikRaporRepository.secondFormInputsState[index] = ''
      setstatusUpdate_FormTable(!statusUpdate_FormTable);
      return;
    }
    
  } else {
    if (event.target.value.length != 10) {
      variableForInputValue.current = '';
      DinamikRaporRepository.secondFormInputsState[index] = ''
      setstatusUpdate_FormTable(!statusUpdate_FormTable);
      return;
    }
    let dataValue = String(event.target.value[0]) + String(event.target.value[1]);
    let monthValue = String(event.target.value[3]) + String(event.target.value[4]);
    let checkTheNaN = event.target.value[0] + event.target.value[1] + event.target.value[3] + event.target.value[4] + event.target.value[6] + event.target.value[7] + event.target.value[8] + event.target.value[9];
    let chekingPointsFlag = event.target.value[2] == '.' && event.target.value[5] == '.';

    console.log('formtable time', dataValue, monthValue);
    console.log('formtable time isNaN', Number.isNaN(Number(checkTheNaN)), checkTheNaN);
    console.log('formtable time chekingPointsFlag', chekingPointsFlag);

    if(Number.isNaN(Number(checkTheNaN)) || 
    Number(dataValue) > 31 || Number(dataValue) < 1 ||
    Number(monthValue) > 12 || Number(monthValue) < 1 || !chekingPointsFlag) {
      variableForInputValue.current = '';
      DinamikRaporRepository.secondFormInputsState[index] = ''
      setstatusUpdate_FormTable(!statusUpdate_FormTable);
      return;
    }
  }

  DinamikRaporRepository.secondFormInputsState[index] = String(event.target.value);
  variableForInputValue.current = String(event.target.value);
  checkingChangeOfStore.current = DinamikRaporRepository.secondFormInputsState[index];

  setstatusUpdate_FormTable(!statusUpdate_FormTable);

}

let formClassName;


function secondForm({id, index, indicatorOfCompletionOfFields}) {

const [statusUpdate_FormTable, setstatusUpdate_FormTable] = useState(false);
const [statusUpdateCalendar_FormTable, setstatusUpdateCalendar_FormTable] = useState([false]);


if (StoreRequest.store[id].secondFormList[index]?.KOLON_TIPI != "TARIH_SAAT" && 
StoreRequest.store[id].secondFormList[index]?.KOLON_TIPI != "TARIH" && 
indicatorOfCompletionOfFields === false) {
  if (StoreRequest.store[id].secondFormList[index]?.BOS_OLABILIRMI == 0) {
    if (DinamikRaporRepository.secondFormInputsState[index] === undefined || DinamikRaporRepository.secondFormInputsState[index] === '') {
      formClassName = 'wrappingFormTable emptyField';
    } else {
      formClassName = 'wrappingFormTable filledField';
    }
  }
} else {
  formClassName = 'wrappingFormTable';
}
console.log('secondForm DinamikRaporRepository.secondFormInputsState[index]', DinamikRaporRepository.secondFormInputsState[index]);


let flagFormTable = DinamikRaporRepository.secondFormInputsState[index] == '' || DinamikRaporRepository.secondFormInputsState[index] == '0' || DinamikRaporRepository.secondFormInputsState[index] == undefined;

let form;

if (StoreRequest.store[id].secondFormList[index]?.KOLON_TIPI == "METIN" || StoreRequest.store[id].secondFormList[index]?.KOLON_TIPI == "SAYISAL") {
  let inputType = '';
  if (StoreRequest.store[id].secondFormList[index]?.KOLON_TIPI == "METIN") {
    inputType = 'text';
  } else if (StoreRequest.store[id].secondFormList[index]?.KOLON_TIPI == "SAYISAL") {
    inputType = 'number';
  }
  form = <div className={formClassName}>
    <p className={`${flagFormTable ? 'activeparagraphFormTable' : 'inactiveparagraphFormTable'}`}> {StoreRequest.store[id].secondFormList[index]?.GORUNEN_ADI } </p>
    <input placeholder={StoreRequest.store[id].secondFormList[index]?.GORUNEN_ADI} className={!flagFormTable ? 'activeinputFormTable' : 'inactiveinputFormTable'} 
    onBlur={ function (event) {
      if (!(event.target.value === '' && DinamikRaporRepository.secondFormInputsState[index] === '')) {
        DinamikRaporRepository.secondFormInputsState[index] = String(event.target.value);
        setstatusUpdate_FormTable(!statusUpdate_FormTable);
        console.log('formtable number|string FormTableRepository.formInputsState', FormTableRepository.formInputsState);
      }

    }}
    defaultValue={DinamikRaporRepository.secondFormInputsState[index] ?? null}
    type={inputType} />
  </div>;
} 


else if (StoreRequest.store[id].secondFormList[index]?.KOLON_TIPI == "NOT") {
  
  form = <div className={formClassName}>

    <p className={`${ flagFormTable ? 'activeparagraphFormTable' : 'inactiveparagraphFormTable'}`}> {StoreRequest.store[id].secondFormList[index]?.GORUNEN_ADI } </p>
    <textarea placeholder={StoreRequest.store[id].secondFormList[index]?.GORUNEN_ADI} className={!flagFormTable ? 'activeinputFormTable' : 'inactiveinputFormTable'} 
    onBlur={ function (event) {
      if (!(event.target.value === '' && DinamikRaporRepository.secondFormInputsState[index] === '')) {
        DinamikRaporRepository.secondFormInputsState[index] = String(event.target.value);
        setstatusUpdate_FormTable(!statusUpdate_FormTable);
        console.log('FormTable NOT FormTableRepository.formInputsState', FormTableRepository.formInputsState);
      } 
      if (event.target.value.length == 0) {
        event.target.parentNode.style.height = '64px';
        event.target.style.height = '35px';
      }

    }}
    defaultValue={DinamikRaporRepository.secondFormInputsState[index] ?? null}
    onKeyDown={function (event) {
      if(event.keyCode == 13 && event.target.scrollHeight != event.target.clientHeight){
        event.target.parentNode.style.height = (event.view.document.activeElement.parentNode.offsetHeight + 24) + 'px';
        event.target.style.height = (event.view.document.activeElement.offsetHeight + 24) + 'px';
      }
    }}
    onChange={function(event) {
      if (event.target.scrollHeight != event.target.clientHeight) {
        event.target.parentNode.style.height = (event.target.scrollHeight + 48) + 'px';
        event.target.style.height = (event.target.scrollHeight + 24) + 'px';
      }
    }}
    onClick={function(event) {
      if (event.target.scrollHeight != event.target.clientHeight) {
        event.target.parentNode.style.height = (event.target.scrollHeight + 48) + 'px';
        event.target.style.height = (event.target.scrollHeight + 24) + 'px';
      } 

    }}/>
    
  </div>;
} 

else if (StoreRequest.store[id].secondFormList[index]?.KOLON_TIPI == "TARIH_SAAT" || StoreRequest.store[id].secondFormList[index]?.KOLON_TIPI == "TARIH") {
  const currentDate = useRef(new Date());
  const htmlRef = useRef(null);
  const eventRef = useRef(new Event("blur"));
  const flagRef = useRef(false);

  const [calendarData, setcalendarData] = useState(['', DinamikRaporRepository.secondFormInputsState[index] ?? null]);
/*
здесь в calendarData записывается массив с 1 пустым элементом, чтобы можно было использовать 
компонент calendaForForms 
*/
  console.log('secondForm calendarData[1] != DinamikRaporRepository.secondFormInputsState[index]', calendarData[1], DinamikRaporRepository.secondFormInputsState[index], calendarData[1] != DinamikRaporRepository.secondFormInputsState[index]);

  if (calendarData[1] != DinamikRaporRepository.secondFormInputsState[index]) {
    DinamikRaporRepository.secondFormInputsState[index] = calendarData[1];
    flagFormTable = DinamikRaporRepository.secondFormInputsState[index] == '' || DinamikRaporRepository.secondFormInputsState[index] == '0' || DinamikRaporRepository.secondFormInputsState[index] == undefined;
  }
  if (indicatorOfCompletionOfFields === false) {
    if (StoreRequest.store[id].secondFormList[index]?.BOS_OLABILIRMI == 0) {
      if (DinamikRaporRepository.secondFormInputsState[index] === undefined || DinamikRaporRepository.secondFormInputsState[index] === '') {
        formClassName = 'wrappingFormTable emptyField';
      } else {
        formClassName = 'wrappingFormTable filledField';
      }
    }
  }

  let maxDate = false; 

  if (StoreRequest.store[id].secondFormList[index]?.GORUNEN_ADI == 'İlk Tarih') {
    let date = currentDate.current.getDate();
    let month = currentDate.current.getMonth() + 1; 
    if (date < 10) {
      date = '0' + date;
    }
    if (month < 10) {
      month = '0' + month;
    }
    maxDate = currentDate.current.getFullYear() + '-' + month + '-' + date + ' 23:59';
  }

  console.log('secondForm calendarData[1] == DinamikRaporRepository.secondFormInputsState[index]', calendarData[1] == DinamikRaporRepository.secondFormInputsState[index]);

  form = <> <div className={formClassName} onBlur={function(event) {

    console.log('formtable TARIH event.target.defaultValue', event.target.defaultValue);
    console.log('formtable TARIH event', event);

    console.log('formtable TARIH DinamikRaporRepository.secondFormInputsState[index]', DinamikRaporRepository.secondFormInputsState[index]);

  }}>
    <p ref={htmlRef} className={`${ flagFormTable ? 'activeparagraphFormTable' : 'inactiveparagraphFormTable'}`} onClick={() => console.log(flagFormTable, DinamikRaporRepository.secondFormInputsState[index])}> {StoreRequest.store[id].secondFormList[index]?.GORUNEN_ADI } </p>
    < Calendar setdataOfTemplate={setcalendarData} index={0} name={StoreRequest.store[id].secondFormList[index]?.GORUNEN_ADI} type={StoreRequest.store[id].secondFormList[index]?.KOLON_TIPI} rangeData={false} dataOfTemplate={calendarData} selectedDateTime={calendarData[1]} maxCalendarDate={maxDate} />
  </div>  
  </>;

/*
  if (StoreRequest.store[id].secondFormList[index]?.GORUNEN_ADI == 'İlk Tarih' && 
  Number(calendarData[1][0] + calendarData[1][1]) > currentDate.current.getDate() && 
  Number(calendarData[1][3] + calendarData[1][4]) >= (currentDate.current.getMonth() + 1) && 
  Number(calendarData[1][6] + calendarData[1][7] + calendarData[1][8] + calendarData[1][9]) >= currentDate.current.getFullYear()) {
    let date = currentDate.current.getDate();
    let month = currentDate.current.getMonth() + 1; 
    if (date < 10) {
      date = '0' + date;
    }
    if (month < 10) {
      month = '0' + month;
    }

    if (calendarData[1].length == 16) {
      setcalendarData(['', date + '.' + month + '.' + currentDate.current.getFullYear() + ' 00:00', calendarData[2] ?? '']);
      //htmlRef.current.nextElementSibling.value = date + '.' + month + '.' + currentDate.current.getFullYear() + ' 00:00';
    } else {
      //htmlRef.current.nextElementSibling.value = date + '.' + month + '.' + currentDate.current.getFullYear();
      setcalendarData(['', date + '.' + month + '.' + currentDate.current.getFullYear(), calendarData[2] ?? '']);

    }
    flagRef.current = true;

  }
  if (StoreRequest.store[id].secondFormList[index]?.GORUNEN_ADI == 'İlk Tarih') {
    useEffect(() =>{
      console.log('useEffect сработал', 
      Number(calendarData[1][0] + calendarData[1][1]), currentDate.current.getDate() );

      if (flagRef.current) {
        let date = currentDate.current.getDate();
        let month = currentDate.current.getMonth() + 1; 
        if (date < 10) {
          date = '0' + date;
        }
        if (month < 10) {
          month = '0' + month;
        }

        if (calendarData[1].length == 16) {
          htmlRef.current.nextElementSibling.value = date + '.' + month + '.' + currentDate.current.getFullYear() + ' 00:00';
        } else {
          htmlRef.current.nextElementSibling.value = date + '.' + month + '.' + currentDate.current.getFullYear();
    
        }

        flagRef.current = false;
        htmlRef.current.nextElementSibling.dispatchEvent(eventRef.current);

      }
      
    }, [calendarData]);

  }*/



} 

else if (StoreRequest.store[id].secondFormList[index]?.KOLON_TIPI == "ACILAN_KUTU") {
  const [templateForPopUp, settemplateForPopUp] = useState(null);
  const regexpForPopUp = new RegExp(`${(templateForPopUp ?? '')}`);
  
  let defaultValueDropWindow;
  if (StoreRequest.store[id].secondFormList[index]?.VIEW_DATA[DinamikRaporRepository.secondFormInputsState[index] - 1]?.VIEW_GORUNEN_ADI !== undefined) {
    defaultValueDropWindow = StoreRequest.store[id].secondFormList[index]?.VIEW_DATA[DinamikRaporRepository.secondFormInputsState[index] - 1]?.VIEW_GORUNEN_ADI;
  } else if (DinamikRaporRepository.secondFormInputsState[index] != 0 && DinamikRaporRepository.secondFormInputsState[index] != undefined) {
    defaultValueDropWindow = DinamikRaporRepository.secondFormInputsState[index];
  } else {
    defaultValueDropWindow = "";
  }
  console.log('formTable ACILAN_KUTU defaultValueDropWindow', defaultValueDropWindow, DinamikRaporRepository.secondFormInputsState[index], StoreRequest.store[id].secondFormList[index]?.VIEW_DATA[Number(DinamikRaporRepository.secondFormInputsState[index]) - 1]?.VIEW_GORUNEN_ADI);

  form = <div className={formClassName} onClick={() => console.log('ACILAN_KUTU', StoreRequest.store[id].secondFormList[index]?.VIEW_DATA[DinamikRaporRepository.secondFormInputsState[index] - 1]?.VIEW_GORUNEN_ADI)} onBlur={() => console.log('ACILAN_KUTU', FormTableRepository.formInputsState)}>
    <p className={`${ flagFormTable ? 'activeparagraphFormTable' : 'inactiveparagraphFormTable'}`} > {StoreRequest.store[id].secondFormList[index]?.GORUNEN_ADI } </p>
    <input placeholder={`${StoreRequest.store[id].secondFormList[index]?.GORUNEN_ADI}`} className={`${!flagFormTable ? 'activeinputFormTable' : 'inactiveinputFormTable'}`} value={templateForPopUp ?? defaultValueDropWindow} 
        onChange={function(event) {
          settemplateForPopUp (event.target.value);
        }} 
        onBlur={ function (event) {
          const checkArrayVariable = checkArray (StoreRequest.store[id].secondFormList[index]?.VIEW_DATA, event.target.value);
          console.log(checkArrayVariable);
          if (!(event.target.value === '' && DinamikRaporRepository.secondFormInputsState[index] === '') && checkArrayVariable[0]) {
            //DinamikRaporRepository.secondFormInputsState[index] = StoreRequest.store[id].secondFormList[index]?.VIEW_DATA[checkArrayVariable[1]].VIEW_DEGER_ADI;
            if (DinamikRaporRepository.secondFormInputsState[index] != StoreRequest.store[id].secondFormList[index]?.VIEW_DATA[checkArrayVariable[1]].VIEW_DEGER_ADI 
              && setRerenderPreFormTable != false) {
              setRerenderPreFormTable([StoreRequest.store[id].secondFormList[index]?.ID, StoreRequest.store[id].secondFormList[index]?.VIEW_DATA[checkArrayVariable[1]].VIEW_DEGER_ADI]);
            }
            DinamikRaporRepository.secondFormInputsState[index] = StoreRequest.store[id].secondFormList[index]?.VIEW_DATA[checkArrayVariable[1]].VIEW_DEGER_ADI;
            setstatusUpdate_FormTable(!statusUpdate_FormTable);
          } else {
            DinamikRaporRepository.secondFormInputsState[index] = '';
            //setstatusUpdate_FormTable(!statusUpdate_FormTable);
            settemplateForPopUp('');
            setstatusUpdate_FormTable(!statusUpdate_FormTable);

          }
          console.log('formtable ACILAN_KUTU DinamikRaporRepository.secondFormInputsState[index]', DinamikRaporRepository.secondFormInputsState[index] === '');
        }}/>
    <div className={`popUpWindow_FormTable`}>
    {templateForPopUp == '' && StoreRequest.store[id].secondFormList[index]?.VIEW_DATA.map(item => <div 
    onMouseDown={function(event) {
      settemplateForPopUp(item.VIEW_GORUNEN_ADI);
    }}>{item.VIEW_GORUNEN_ADI}</div>)}
    {templateForPopUp != '' && StoreRequest.store[id].secondFormList[index]?.VIEW_DATA.filter(item => (regexpForPopUp.test((item.VIEW_GORUNEN_ADI)))).map(item => <div 
    onMouseDown={function(event) {
      settemplateForPopUp(item.VIEW_GORUNEN_ADI); 
    }}>{item.VIEW_GORUNEN_ADI}</div>)}
    </div>
  </div>;
} 

else if (StoreRequest.store[id].secondFormList[index]?.KOLON_TIPI == "ONAY_KUTUSU") {
  formClassName = 'wrappingFormTable';

  if (DinamikRaporRepository.secondFormInputsState[index] == '') {
    DinamikRaporRepository.secondFormInputsState[index] = 'false';
  }
  form = <div className={formClassName}>
    <div className={`flagTrueFalse`} onClick={function () {
      if (DinamikRaporRepository.secondFormInputsState[index] == undefined || DinamikRaporRepository.secondFormInputsState[index] == 'false' || DinamikRaporRepository.secondFormInputsState[index] == '') {
        DinamikRaporRepository.secondFormInputsState[index] = 'true';
        setstatusUpdate_FormTable(!statusUpdate_FormTable);
      } else if (DinamikRaporRepository.secondFormInputsState[index] == 'true') {
        DinamikRaporRepository.secondFormInputsState[index] = 'false';
        setstatusUpdate_FormTable(!statusUpdate_FormTable);
      } 
    }}>
      <p> {StoreRequest.store[id].secondFormList[index]?.GORUNEN_ADI } </p>
      <div>{DinamikRaporRepository.secondFormInputsState[index] == 'true' && flagTrueFalse}</div>
    </div>
  </div>;
} 

else {
  form = <div className={formClassName} onClick={() => console.log('formtable StoreRequest.store[id].secondFormList[index]?.KOLON_TIPI', StoreRequest.store[id].secondFormList[index]?.KOLON_TIPI)}>
    <p className={`${flagFormTable ? 'activeparagraphFormTable' : 'inactiveparagraphFormTable'}`}> {StoreRequest.store[id].secondFormList[index]?.GORUNEN_ADI } </p>
    <input placeholder={StoreRequest.store[id].secondFormList[index]?.GORUNEN_ADI} className={!flagFormTable ? 'activeinputFormTable' : 'inactiveinputFormTable'} 
    onBlur={ function (event) {
      if (!(event.target.value === '' && DinamikRaporRepository.secondFormInputsState[index] === '')) {
        DinamikRaporRepository.secondFormInputsState[index] = String(event.target.value);
        setstatusUpdate_FormTable(!statusUpdate_FormTable);
        console.log(FormTableRepository.formInputsState);
      }
    }}
    defaultValue={DinamikRaporRepository.secondFormInputsState[index] ?? null}/>
  </div>;
}


return <>
  
  {form}

  

</>;
}

export default secondForm;



//  <Calendar state={0} setState={setstatusUpdateCalendar_FormTable} index={0} name={id} repository={'FormTable'} placeholderData={StoreRequest.store[id].secondFormList[index]?.GORUNEN_ADI} defaultValue={DinamikRaporRepository.secondFormInputsState[index] ?? 0} propTimepicker={StoreRequest.store[id].secondFormList[index]?.KOLON_TIPI == "TARIH_SAAT" ? true : false} statusOpen={(timeElementUpdate_FormTable && condition != "KITLI") ? true : false} />
/*
нужно как то унифицировать календарь, чтобы он записывал данные в разные хранилища в зависимости от 
компонента, в котором он применяется. 
Может передавать хранилище в пропсы? 

Предыдущая задача решена, теперь для календаря данные можно передавать в props.
Но вот как этот календарь воткнуть в этот элемент? Теперь он раотает со state.
Допустим в календарь можно передавать хранилище, однако теперь в него нужно передать функцию 
изменения стейта, и в этот стейт будут записываться данные. А данные хранятся не в стейте, а в 
хранилище. И код переписывать нельзя, иначе календарь не сможет использовать другой компонент. 

Может сделать такой стейт? а при обновлении сравнивать данные из хранилища и данные в стейте?
В таком случае нужно этот код записать только в блоке для работы с календарем, чтобы не затрагивать 
остальные компоненты.  

Возникла другая проблема, в календаре для форм нет возможности дать значение заранее. 


Возникла еще одна проблема, не получается придать тексту внутри дива и инпуту нужные классы. 
По какой то причине классы не обновляются. 

Итак, возникла проблема с изменением классов поля ввода. Установить причину пока не удалось, 
локализовать проблему тоже пока не удалось. Список отклонений: 

1. данные в переменной flagFormTable не изменяются, хотя должны
2. данные в хранилище DinamikRaporRepository.secondFormInputsState[index] изменяются с ошибкой
3. по какой то причине классы не изменяются даже при условии изменения переменной flagFormTable. 

Сначала нужно локализовать проблему.
Проблем с изменением данных в input нет
В тексте над input переменная flagFormTable поставлена в правильное положение

Найдено место одной из проблем. В компоненте calendar переменна flag отвечает за переключение между 
активным и неактивным классами, изменение начального значения никак не влияет на input, хотя должно. 

Изменение класса при удалении и добавлении класса зафиксировано, нужно еще тестировать.
В результате тестирования выяснено, что переменная flag работает без отклонений, как и должна. 
Причина отсутствия видимых изменений предположительно в неработающем изменении классов в тексте 
над input.

По всей видимости проблема возникает при попытке связать state и хранилище, а после изменений вновь 
выяснить значение переменной flagFormTable. 

Нужно для начала попытаться синхронизировать изменения и значения в state и в хранилище. 
Синхронизировал зеачения в state и в хранилище успешно, значение переменной flagFormTable теперь 
вычисляется правильно. 

Осталась проблема со стилем input, он не исчезает после деактивации. 
Эта проблема решена путем присвоения класса как у поля в компоненте formtable. 

В будущем нужно будет переписать css файлы, чтобы не было повторяющегося кода. 

*/

/*
    <input placeholder={StoreRequest.store[id].secondFormList[index]?.GORUNEN_ADI} className={!flagFormTable ? 'activeinputFormTable colorInputChild2' : 'inactiveinputFormTable colorInputChild2'} 
    value={variableForInputValue.current}
    onChange={function (event) {
      
      if (typeof variableForInputValue.current == 'string' && 
      event.target.value.length > variableForInputValue.current.length) {
        if (StoreRequest.store[id].secondFormList[index]?.KOLON_TIPI == "TARIH_SAAT") {
          switch (event.target.value.length) {
            case 2: 
              event.target.value+= '.';
            break;
            case 5: 
              event.target.value+= '.';
            break;
            case 10: 
              event.target.value+= ' ';
            break;
            case 13: 
              event.target.value+= ':';
            break;
          }
        } else {
          switch (event.target.value.length) {
            case 2: 
              event.target.value+= '.';
            break;
            case 5: 
              event.target.value+= '.';
            break;
          }
        }
      }
      variableForInputValue.current = event.target.value;
      setstatusUpdate_FormTable(!statusUpdate_FormTable);
    }}
    onBlur={ function (event) {
      if (!(event.target.value === '' && DinamikRaporRepository.secondFormInputsState[index] === '')) {

        checkAndChangeFormTableDate (event, id, index, variableForInputValue, checkingChangeOfStore, statusUpdate_FormTable, setstatusUpdate_FormTable );
      }
    }}

    onKeyDown={ function (event) {
      if (event.target.value != DinamikRaporRepository.secondFormInputsState[index] && event.keyCode == 13) {
        
        checkAndChangeFormTableDate (event, id, index, variableForInputValue, checkingChangeOfStore, statusUpdate_FormTable, setstatusUpdate_FormTable );
      }
    }}
    maxLength={StoreRequest.store[id].secondFormList[index]?.KOLON_TIPI == "TARIH_SAAT" ? 16 : 10}
    />
*/

/*
Нужно сделать так, чтобы при выборе начальной даты, нельзя было выбрать дату больше сегодняшнего дня. 
Как это сделать? 

1. можно установить ограничение в самом календаре. Однако в таком случае нужно будет написать код в функциях 
в календаре. Допустим есть возможность установить максимальную дату в самом календаре, 
но как установить дату в инпуте? Получается, что это единственный возможный вариант. 
Недостаток такого подхода в том, что придется переписывать код внутри сложного компонента, который 
уже хорошо работает. И после такого переписывания, данный компонент сможет работать только с secondForm. 


2. можно попробовать найти выводимый элемент с помощью метода findDOMNode 
и вручную устанавливать значение для input. 
Однако в таком случае непонятно, как установленное значение привести в действие? 
Можно эмулировать событие нажатия на кнопку enter. 
А если это будет происходить во время набора даты? 
findDOMNode работает только с классовыми компонентами. 

3. Можно просто попробовать изменить state. Нет, это не повлияет на инпут и календарь.
Это может сработать, если выбрать инпут и сделать событие onBlur или onKeyDown. 
Можно использовать ref на теге p, потом найти следующий за ним input, 
а потом применить к input событие. 

Нужно использовать useEffect чтобы при каждом обновлении стейта с данными 
запускать событие onBlur. 



Алгоритм обновления input и calendar: 
1. в input выбирается неправильная дата и обновляется компонент
2. срабатывает условие, флаг принимает значение true, state обновляет компонент
3. к обновляющему state привязываем useEffect. В нем в условии проверяем flag, 
   потом в input кладем нужное значение. 
4. Эмулируем событие blur на input и flag устанавливаем значение false. 

*/
