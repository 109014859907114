//import './Form.css';
import { useState, useRef, useEffect} from 'react';
import { useSelector } from 'react-redux';

import adress from '../adress.js'
import MapRepository from './classMapRepository.js'


import MapComponent from './MapComponent';
import MapTable from './MapTable';
import Right_Sidebar from './Right_Sidebar';


import flagOfMap from '../flagOfMap.js'


let dataJson = [
  {
      "ID": 1,
      "TURU": "TUMU",
      "DEPARTMANI": "TUMU",
      "USER_STATU": "TUMU",
      "TARIH": "30-05-2024",
      "KULLANICI": "Murat Gündoğdu",
      "ADET": 3
  },
  {
    "ID": 2,
    "TURU": "TUMU",
    "DEPARTMANI": "TUMU",
    "USER_STATU": "TUMU",
    "TARIH": "30-05-2024",
    "KULLANICI": "Murat Gündoğdu",
    "ADET": 3
  },
  {
    "ID": 3,
    "TURU": "TUMU",
    "DEPARTMANI": "TUMU",
    "USER_STATU": "TUMU",
    "TARIH": "30-05-2024",
    "KULLANICI": "Murat Gündoğdu",
    "ADET": 3
  },
  {
    "ID": 4,
    "TURU": "TUMU",
    "DEPARTMANI": "TUMU",
    "USER_STATU": "TUMU",
    "TARIH": "30-05-2024",
    "KULLANICI": "Murat Gündoğdu",
    "ADET": 3
  },
  {
    "ID": 5,
    "TURU": "TUMU",
    "DEPARTMANI": "TUMU",
    "USER_STATU": "TUMU",
    "TARIH": "30-05-2024",
    "KULLANICI": "Murat Gündoğdu",
    "ADET": 3
  },
  {
    "ID": 6,
    "TURU": "TUMU",
    "DEPARTMANI": "TUMU",
    "USER_STATU": "TUMU",
    "TARIH": "30-05-2024",
    "KULLANICI": "Murat Gündoğdu",
    "ADET": 3
  },
  {
    "ID": 7,
    "TURU": "TUMU",
    "DEPARTMANI": "TUMU",
    "USER_STATU": "TUMU",
    "TARIH": "30-05-2024",
    "KULLANICI": "Murat Gündoğdu",
    "ADET": 3
  },
]


const gearPath = <path d="M11.366 22.564l1.291-1.807-1.414-1.414-1.807 1.291c-0.335-0.187-0.694-0.337-1.071-0.444l-0.365-2.19h-2l-0.365 2.19c-0.377 0.107-0.736 0.256-1.071 0.444l-1.807-1.291-1.414 1.414 1.291 1.807c-0.187 0.335-0.337 0.694-0.443 1.071l-2.19 0.365v2l2.19 0.365c0.107 0.377 0.256 0.736 0.444 1.071l-1.291 1.807 1.414 1.414 1.807-1.291c0.335 0.187 0.694 0.337 1.071 0.444l0.365 2.19h2l0.365-2.19c0.377-0.107 0.736-0.256 1.071-0.444l1.807 1.291 1.414-1.414-1.291-1.807c0.187-0.335 0.337-0.694 0.444-1.071l2.19-0.365v-2l-2.19-0.365c-0.107-0.377-0.256-0.736-0.444-1.071zM7 27c-1.105 0-2-0.895-2-2s0.895-2 2-2 2 0.895 2 2-0.895 2-2 2zM32 12v-2l-2.106-0.383c-0.039-0.251-0.088-0.499-0.148-0.743l1.799-1.159-0.765-1.848-2.092 0.452c-0.132-0.216-0.273-0.426-0.422-0.629l1.219-1.761-1.414-1.414-1.761 1.219c-0.203-0.149-0.413-0.29-0.629-0.422l0.452-2.092-1.848-0.765-1.159 1.799c-0.244-0.059-0.492-0.109-0.743-0.148l-0.383-2.106h-2l-0.383 2.106c-0.251 0.039-0.499 0.088-0.743 0.148l-1.159-1.799-1.848 0.765 0.452 2.092c-0.216 0.132-0.426 0.273-0.629 0.422l-1.761-1.219-1.414 1.414 1.219 1.761c-0.149 0.203-0.29 0.413-0.422 0.629l-2.092-0.452-0.765 1.848 1.799 1.159c-0.059 0.244-0.109 0.492-0.148 0.743l-2.106 0.383v2l2.106 0.383c0.039 0.251 0.088 0.499 0.148 0.743l-1.799 1.159 0.765 1.848 2.092-0.452c0.132 0.216 0.273 0.426 0.422 0.629l-1.219 1.761 1.414 1.414 1.761-1.219c0.203 0.149 0.413 0.29 0.629 0.422l-0.452 2.092 1.848 0.765 1.159-1.799c0.244 0.059 0.492 0.109 0.743 0.148l0.383 2.106h2l0.383-2.106c0.251-0.039 0.499-0.088 0.743-0.148l1.159 1.799 1.848-0.765-0.452-2.092c0.216-0.132 0.426-0.273 0.629-0.422l1.761 1.219 1.414-1.414-1.219-1.761c0.149-0.203 0.29-0.413 0.422-0.629l2.092 0.452 0.765-1.848-1.799-1.159c0.059-0.244 0.109-0.492 0.148-0.743l2.106-0.383zM21 15.35c-2.402 0-4.35-1.948-4.35-4.35s1.948-4.35 4.35-4.35 4.35 1.948 4.35 4.35c0 2.402-1.948 4.35-4.35 4.35z"></path>;

function MapWrapping({id}) {
  console.log('MapWrapping MapRepository.data', MapRepository.data);

  const [componentUpdate, setUpdate] = useState([0]);
  const flagOfplate_gearSwitch = useRef(false);
  const numberOfChildComponent = useRef(0);
  const selectedBlock = useRef([-1, -1]);
  console.log('selectedBlock', selectedBlock.current);


  useEffect(() => {
    console.log('MapWrapping смонтировался');
    MapRepository.data = 0; 
  }, []);

  const token = useSelector((state) => state.tokenOfSession.token);

  if (MapRepository.data == 0) {
    fetch(`https://creaapi.com/${adress[0]}/CrealogTakipOrtamDenetimi.svc/GetGuncelTakip/?sessionId=${token}&araclarId=0`)
    .then(response => response.json())
    .then(function (json) {
      MapRepository.data = json.GetGuncelTakipResult;
      console.log('fetch MapWrapping MapRepository.data', MapRepository.data);
      setUpdate([0]);
    });
  }

  if (selectedBlock.current[1] != -1) {
    numberOfChildComponent.current = 2
  }


  console.log('MapWrapping MapRepository.data', MapRepository.data);


/*
  let gearSwitch = <div className="gearSwitch" 
  onClick={function() {
    flagOfplate_gearSwitch.current = !flagOfplate_gearSwitch.current;
    setUpdate([0]);
  }}>
    <div className='plate_gearSwitch' style={{height: `${flagOfplate_gearSwitch.current ? '70px' : '0px'}`, overflow: 'hidden'}}>
      <div onClick={() => numberOfChildComponent.current = 2}>Sidebar</div>
      <div onClick={() => numberOfChildComponent.current = 1}>Table</div>
      <div onClick={() => numberOfChildComponent.current = 0}>Harita</div>
    </div>
  </div>;
*/
  let gearSwitch = <div className='gearSwitch' 
    style={{height: `${flagOfplate_gearSwitch.current ? '70px' : '0px'}`, overflow: 'hidden'}}
    onClick={function() {
      flagOfplate_gearSwitch.current = !flagOfplate_gearSwitch.current;
      setUpdate([0]);
    }}>
      <div onClick={() => numberOfChildComponent.current = 2}>Sidebar</div>
      <div onClick={() => numberOfChildComponent.current = 1}>Table</div>
      <div onClick={() => numberOfChildComponent.current = 0}>Harita</div>
    </div>;

  if (flagOfMap[0]) {
    return <iframe src={`https://www.createch.com.tr/cl/harita/${token}`} style={{width: '100vw', height: '90vh', border: 'none', marginTop: '1px'}}></iframe>;
  }
  //return <iframe src={`https://www.createch.com.tr/cl/harita/${token}`} style={{width: '100vw', height: '90vh', border: 'none', marginTop: '1px'}}></iframe>;
  if (MapRepository.data == 0) {
    console.log('MapWrapping MapRepository.data == 0', MapRepository.data);

    return <></>;
  }
  return <> 
    <div className='map' style={{height: '90vh'}}></div>
    <MapComponent class={'map'} token={token} selectedBlock={selectedBlock.current} MapWrappingUpdate={setUpdate} />
    <svg className="gearMap" viewBox="0 0 32 32"   
    onClick={function() {
      flagOfplate_gearSwitch.current = !flagOfplate_gearSwitch.current;
      setUpdate([0]);
    }}>{gearPath}</svg>
    {gearSwitch}
    {numberOfChildComponent.current == 1 && <MapTable data={dataJson} columnsTable={Object.keys(dataJson[0])} id={id} />}
    {numberOfChildComponent.current == 2 && <Right_Sidebar selectedBlock={selectedBlock.current} MapWrappingUpdate={setUpdate} />}
  </>
}
  
export default MapWrapping;


/*

03.07.2024

Основная задача: Нужно сделать соединение с веб сокетами.
Подзадачи: 
1. как определить, что нужно открывать именно карту? По ip страницы это не сильно понятно.
Это можно выяснить потом. Пока опустим эту задачу и перейдем у следующим.

2. как нужно подключать веб сокеты?
Сначала нужно подключить карту. 
Сокеты тоже лучше оставить на потом


3. Нужно сделать отдельный компонент для карт и отдельный компонент для соединения и html 
элементов. 
Карта уже есть, на нее нужно только нанести элементы. 
Нужно сделать правый sidebar и таблицу снизу. 
Нужно сделать кнопку для переключения таблицы, сайдбара и режима без таблицы и сайдбара. 
Как это сделать?
Может сделать две кнопки? 
Где их расположить и как оформить? 
Можно ли менять устоявшийся дизайн? 
Можно использовать шестеренку как на оригинальном сайте.
Как организовать переключение разных элементов и переключение самого переключателя?
Сделать 2 стейта?
Один для переключения переключателя и один для элементов? Не слишком ли много? 
А как еще отрегулировать появление и исчезание div со списком?
использовать ref? 
Может использовать один стейт для обновления и несколько рефов?
Так будет гораздо удобнее закрывать div со списком. 
Это было хорошим решением. 

Возникает проблема с установкой расположения шестеренки и списка. 
Если шестеренка и список расположены вместе, то не удается их расположить как надо, 
а если раздельно, то не удается обработать клик на шестеренку. Шестеренка записана в переменную, 
и не удастся просто так в нее записать функцию. 
Но что если в переменную записать не все svg, а только путь? тогда тег svg можно написать уже на месте, 
и добавить к нему путь из переменной. 

4. нужно как то оптимизировать элементы так, чтобы они обновлялись в реальном времени, 
и при этом не было проблем с производительностью.  


*/

//    <MapTable data={dataJson} columnsTable={Object.keys(dataJson[0])} id={id} />

/*
Нужно сделать так, чтобы при открытии таблицы страница прокручивалась вниз
*/

/*
https://creaapi.com/crealog/CrealogTakipOrtamDenetimi.svc/GetGuncelTakip/?sessionId=3ae84a65-ba18-42af-9cc2-5b5bfc308fb0&araclarId=0

*/

   
/*
<> 
    <div className='map' style={{height: '90vh'}}></div>
    <MapComponent class={'map'} token={token} />
    <svg className="gearMap" viewBox="0 0 32 32"   
    onClick={function() {
      flagOfplate_gearSwitch.current = !flagOfplate_gearSwitch.current;
      setUpdate([0]);
    }}>{gearPath}</svg>
    {gearSwitch}
    {numberOfChildComponent.current == 1 && <MapTable data={dataJson} columnsTable={Object.keys(dataJson[0])} id={id} />}
    {numberOfChildComponent.current == 2 && <Right_Sidebar />}
  </>
*/
