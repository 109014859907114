import { useState, useRef } from 'react';
import './header.css';
import './headerAdaptive.css';
import StoreRequestTypeComponent from '../Components/classRequestLimiter';
import { useDispatch, useSelector } from 'react-redux';

const folder = <svg className='svgFolder' onClick={event => event.stopPropagation()} viewBox="0 0 34 34">
<path d="M26 30l6-16h-26l-6 16zM4 12l-4 18v-26h9l4 4h13v4z"></path>
</svg>;

const file = <svg className='svgFolder' onClick={event => event.stopPropagation()} viewBox="0 0 34 34">
<path d="M28.681 7.159c-0.694-0.947-1.662-2.053-2.724-3.116s-2.169-2.030-3.116-2.724c-1.612-1.182-2.393-1.319-2.841-1.319h-15.5c-1.378 0-2.5 1.121-2.5 2.5v27c0 1.378 1.122 2.5 2.5 2.5h23c1.378 0 2.5-1.122 2.5-2.5v-19.5c0-0.448-0.137-1.23-1.319-2.841zM24.543 5.457c0.959 0.959 1.712 1.825 2.268 2.543h-4.811v-4.811c0.718 0.556 1.584 1.309 2.543 2.268zM28 29.5c0 0.271-0.229 0.5-0.5 0.5h-23c-0.271 0-0.5-0.229-0.5-0.5v-27c0-0.271 0.229-0.5 0.5-0.5 0 0 15.499-0 15.5 0v7c0 0.552 0.448 1 1 1h7v19.5z"></path>
</svg>;

function Menu({dataOfMenu, setarrOfTopMenu}) {
console.time('Время выполнения Menu.js');
const [linksOfMenu, setlinksOfMenu] = useState([]);

const dispatch = useDispatch();

const refMenu = useRef(0);
const refMenuItemHeights = useRef(0);


const refdataOfMenu = useRef(0);
if (refdataOfMenu.current != dataOfMenu && refdataOfMenu.current != Object.keys(dataOfMenu).length) {
  refdataOfMenu.current = dataOfMenu;
  console.log('menu dataOfMenu', Object.keys(dataOfMenu).length);





/*
function additionNestingLevel принцип работы.
Мы получаем многомерный объект, где за слоем объекта идет слой массивов, потом все повторяется.
Мы проходим полученый объект в ширину с помощью циклов, в высоту с помощью самовызова (рекурсии)
вложенной функции variable. Два уровня проходим за один вызов функции variable,
так как знаем к какому именно свойству нужно обращаться, к свойству MODULLER.
Все нужные данные при прохождении объекта мы записываем в arrVariable, который потом и возвращаем.
Сложность состоит в том, как записать данные с сохранением изначальной структуры.
Проблема решена с помощью способа записи данных и 
передачи номера итерации и номера вложенности в параметры при самовызове функции variable.
Нужные данные записываем в объект: [obj, []].
*/
function additionNestingLevel (obj) {
  let arrVariable = [];

  function variable (obj, arr = [0], arrForRecord = arrVariable) {
    let recordArr = arrForRecord;
    if (obj.MODULLER != null) {
      for (let i = 0; i < obj.MODULLER.length; i++) {
        let arrSecond = [...arr]; 
        arrSecond.push(i);
        let recordingOfObj = {
          mainData: `уровень вложенности ${arr.length}, номер элемента  ${i}, название элемента  ${obj.MODULLER[i].ADI}`,
          ADI_FOLDER: obj.MODULLER[i].ADI,
          FORM_RAPORLAR: obj.MODULLER[i].FORM_RAPORLAR.map(function(item) {
            let obj = {};
            obj.GORUNEN_ADI = item.GORUNEN_ADI;
            obj.ID = item.ID;
            if (item.CLASS_NAME == "#/index/harita") {
              obj.ID = item.ID + '_harita';
              console.log('menu additionNestingLevel item', item);
            }
            //console.log('menu additionNestingLevel item', item);
            obj.TURU = item.TURU;
            return obj; 
          })
        };
        recordArr.push([recordingOfObj, []]);
        variable (obj.MODULLER[i], arrSecond, recordArr[i][1]); 
      } 
    } 
  }
  variable (obj)
  return arrVariable;
} 


function goTotheLink (event) {
  let variable = (event.target.className).split(' ');
  dispatch({type: 'CHANGE_PAGE_NAME', pageName: `${variable[1]}` });
  console.log('event.target.className)', variable);
  StoreRequestTypeComponent.pageType = variable[3];
  setarrOfTopMenu([variable[1], variable[2], variable[3]]);
}


function wrappinginTags (arr) {
  let boxOfElements = [];

  function variableWrappinginTags (arrVar, countOfLevelsVar = 0) {
    let arrForRecordVariableWrappinginTags = [];
    let countOfLevels = countOfLevelsVar;
    if (Array.isArray(arrVar) && arrVar.length != 0) {
      for (let i = 0; i < arrVar.length; i++) {
        let countOfLevels0 = countOfLevels + 1;
        let secondaryLinksArr = arrVar[i][0].FORM_RAPORLAR.map(function(item) {
          return <div className={`level${countOfLevels0} ${item.ID} ${(item.GORUNEN_ADI).replace(/ /g, '_')} ${item.TURU} secondaryLinks`} 
          onClick={event => goTotheLink (event)}
          key={String(item.GORUNEN_ADI)}> <span>{file}</span> {
            item.GORUNEN_ADI
          }</div>
        });
        arrForRecordVariableWrappinginTags.push(<div className={`level${countOfLevels}`} key={arrVar[i][0].ADI_FOLDER}> 
          <div 
          className={`level${countOfLevels0} nameOfFolder`}
          style={{marginLeft: `${countOfLevels * 10}px`}}
          onClick={function (event) {
            event.stopPropagation();
            if(refMenuItemHeights.current != 0) {
              let variableName = event.target.childNodes[3].wholeText;

              if (refMenuItemHeights.current[variableName][1] == refMenuItemHeights.current[variableName][2]) {
                refMenuItemHeights.current[variableName][0].style.height = '0px';
                refMenuItemHeights.current[variableName][2] = 0;
              } else {
                refMenuItemHeights.current[variableName][0].style.height = `${refMenuItemHeights.current[variableName][1]}px`;
                refMenuItemHeights.current[variableName][2] = refMenuItemHeights.current[variableName][1];
              }


            }}}> <span onClick={event => event.stopPropagation()}>{folder}</span>  {arrVar[i][0].ADI_FOLDER} </div>
        <div className={`level${countOfLevels0}`} style={{ overflow: 'hidden', transition: 'height 0.5s', marginLeft: `${countOfLevels0 * 10}px`}} > 
        {secondaryLinksArr}
        {
          variableWrappinginTags (arrVar[i][1], countOfLevels0)
        }</div>
      </div>);
      }
    } 
    if (arrForRecordVariableWrappinginTags.length != 0) {
      return arrForRecordVariableWrappinginTags;
    }
  }
  boxOfElements.push(variableWrappinginTags (arr));
  return boxOfElements;
}

if (Object.keys(dataOfMenu).length != 0 && refMenu.current == 0) {
  refMenu.current = wrappinginTags(additionNestingLevel (dataOfMenu.GetModullerFormlarResult));
  console.log('menu dataofmenu', dataOfMenu);
  //console.log('menu dataofmenu1', additionNestingLevel (dataOfMenu.GetModullerFormlarResult));
  queueMicrotask(function() {
    //console.log('middleComponent queueMicrotask document', document.getElementsByClassName('middleComponentConteiner')[0].offsetHeight);
    //refHeightSize.current = document.getElementsByClassName('MiddleComponentForThirdForm')[0].offsetHeight + 5;
    //setheightSize(`${refHeightSize.current}px`);
    let variable = document.getElementsByClassName('nameOfFolder');
    refMenuItemHeights.current = {};

    for (let i = 0; i < variable.length; i++) {
      refMenuItemHeights.current[variable[i].childNodes[3].wholeText] = [variable[i].nextSibling, variable[i].nextSibling.clientHeight, 0];
    }
    for (let elem of variable) {
      elem.nextSibling.style.height = '0px'
    }

    //refMenuItemHeights.current = document.getElementsByClassName('nameOfFolder')[0].childNodes[3].wholeText;
    console.log('refMenuItemHeights.current', refMenuItemHeights.current);
  });
}

}

const menu =  <div className='Home_page_link'>
  {refMenu.current}
</div>;
console.timeEnd('Время выполнения Menu.js');

return <> {menu}</>;

}

export default Menu;


/*
Задача: Нужно сделать плавное изменение размеров меню. 
Как это сделать?
Допустим возможно узнать размер каждого меню. Однако как это 
значение передать в css файл? 
Можно изменять стиль в самом коде, однако тогда придется проводить 
вычисления заново и делать каждый раз повторный рендеринг. 
Ладно, допустим еще можно сделать повторный рендеринг, 
однако как оптимизировать вычисления?
Результат additionNestingLevel можно записать в ref. 
Можно даже вынести эту функцию за пределы компонента, чтобы код не выполнялся каждый раз. 
Однако wrappinginTags тоже не маленькая функция, там еще и рекурсия есть. 
Можно ли как нибудь вносить изменения в теги без повторного ререндеринга?
Можно вносить изменения напрямую в dom. 
Это неплохое решение. 
Однако нужно получить ссылку на элемент dom. 
И этого нельзя сделать до первого выведения элементов на экран. 
Ну и что, все равно здесь потребуется повторный рендеринг. 
Хорошо, но как организовать получение ссылок в dom? 
Вот так, отыскать элементы по классам и сохранить результат в массив или объект, 
а в сам элемент записать код, в котором будет проверка на наличие значения и 
в случае его наличия, изменение свойства dom.


Как найти все нужные элементы в dom? 
У всех элементов есть класс nameOfFolder, 
однако как выделить каждый элемент в массиве элементов? 
точно ли они будут выделены по порядку?
*/
