
import React, {Component} from 'react'
import './MapComponent.css';
import '../../../node_modules/leaflet/dist/leaflet.css';
import L from 'leaflet';
import "leaflet.markercluster";

/*
const HARITA_DATA = {
  "KONUMLAR": [
      {
          "ENLEM": 38.396711,
          "BOYLAM": 27.035818,
          "RENK": "#1345aa",
          "ACIKLAMA": "ACIKLAMA 1",
          "TANIM": null
      },
      {
          "ENLEM": 38.39587,
          "BOYLAM": 27.019811,
          "RENK": "#FD0E35",
          "ACIKLAMA": "ACIKLAMA 2",
          "TANIM": null
      },
      {
          "ENLEM": 38.399166,
          "BOYLAM": 27.065344,
          "RENK": "#ccbea5",
          "ACIKLAMA": "ACIKLAMA 2",
          "TANIM": null
      },
      {
          "ENLEM": 38.396711,
          "BOYLAM": 27.035818,
          "RENK": "#1345aa",
          "ACIKLAMA": "ACIKLAMA 4",
          "TANIM": null
      },
      {
          "ENLEM": 38.39587,
          "BOYLAM": 27.019811,
          "RENK": "#FD0E35",
          "ACIKLAMA": "ACIKLAMA 5",
          "TANIM": null
      },
      {
          "ENLEM": 38.399166,
          "BOYLAM": 27.065344,
          "RENK": "#ccbea5",
          "ACIKLAMA": "ACIKLAMA 6",
          "TANIM": null
      }
  ],
  "LISTE": [
      {
          "TEXT": "Deneme 1 2",
          "RENK": "#1345aa"
      },
      {
          "TEXT": "Deneme 2 2",
          "RENK": "#FD0E35"
      },
      {
          "TEXT": "Deneme 3 2",
          "RENK": "#ccbea5"
      }
  ]
};
*/

let HARITA_DATA;
/*
Здесь глобальная переменная нужна для обхода строгой типизации leaftlet. 
По всей видимости, в leaflet данные из props не проходят проверку типа, и 
библиотека создает ошибку. 
Если же ссылку на данные скопировать в переменную, то проверка типпа 
проходит. 
Здесь нужно помнить, что больше одного компонента выводить нельзя, 
так как в таком случае все компоненты будут использовать одну и ту же переменную HARITA_DATA. 
В таком случае код данного компонента нужно переписать под использование данных из 
хранилища напрямую. Это будет снижать производительность, так как данные приходят не в готовом 
виде, а в виде строки json, и их каждый раз придется переводить в код. 


*/

let eventMapUpdate = new CustomEvent("MapUpdate", {
  detail: { id: "0" }
});
//document.dispatchEvent(eventMapUpdate);

function changeMarkers (arrOfMarkers, colors, map, HARITA_DATA) {
  console.log('changeMarkers вызвана');
  console.log(arrOfMarkers);
  

  for (let elem of arrOfMarkers) {
    elem.remove();

  }
  console.log(colors);
  arrOfMarkers.length = 0;
  /*
  здесь и далее нужно внимательно смотреть за операциями с arrOfMarkers. 
  Очень важно чтобы arrOfMarkers ссылалась на this.refMarkers, и эту ссылку 
  не потеряла. Это также важно и для других данных из параметров этой функции.
  */


  for (let i = 0; i < HARITA_DATA["LISTE"].length; i++) {
    let consilienceOfColors = colors.has(HARITA_DATA["KONUMLAR"][i]["RENK"]);
    if (!consilienceOfColors) {
      let markers = new L.MarkerClusterGroup({iconCreateFunction: function (cluster) {
        let newDiv0 = document.createElement("div");
        newDiv0.innerHTML = String(i);
        newDiv0.setAttribute("style", `background-color: ${HARITA_DATA["KONUMLAR"][i]["RENK"]}; padding-left: 9px; border-radius: 50%;`); 
  
        return L.divIcon({ 
            html: newDiv0,
            className: 'mycluster',
            iconSize: L.point(30, 30) // размер иконки
        });
        }
      }); 
  
      for (let j = 0; j < HARITA_DATA["KONUMLAR"].length; j++) {
        if (HARITA_DATA["KONUMLAR"][j]["RENK"] == HARITA_DATA["LISTE"][i]["RENK"]) {
          let newDiv = document.createElement("div");
          newDiv.innerHTML = String(j);
          newDiv.setAttribute("style", `background-color: ${HARITA_DATA["KONUMLAR"][i]["RENK"]}; padding-left: 9px; border-radius: 50%;`); 
    
          //this.refMarkers[i] = L.marker([HARITA_DATA["KONUMLAR"][i]["ENLEM"], HARITA_DATA["KONUMLAR"][i]["BOYLAM"]], {icon: L.divIcon({className: 'my-div-icon', html: newDiv, iconSize: [30, 30]})});
          markers.addLayer(
            L.marker([HARITA_DATA["KONUMLAR"][j]["ENLEM"], HARITA_DATA["KONUMLAR"][j]["BOYLAM"]], {icon: L.divIcon({className: 'my-div-icon', html: newDiv, iconSize: [30, 30]})})
          );
        }
      }
      arrOfMarkers.push(markers);
      map.addLayer(markers);
    }
  }
}

class Map extends React.Component {
             
  constructor(props) {
      super(props);
      this.state = {
        availabilityOfMeaning: 0,
        dialogWindow: false,
        renderThisComponent: [0],
        colors: [],
      };
      this.refMap = null;
      this.refReturnMap = null;
      this.refColors = new Set;
      this.refMarkers = [];
      this.refСustomControl = 0;
      //this.listenFunc = this.listenFunc.bind(this);
      this.listenFunc = this.listenFunc.bind(this);
  }

 
  componentDidMount() {
    HARITA_DATA = this.props.HARITA_DATA;
    console.log('HARITA_DATA', HARITA_DATA);
    document.addEventListener("MapUpdate", this.listenFunc);

    let div = document.createElement("div");
    div.setAttribute('id', 'map'); 
    div.setAttribute('style', 'width: 100vw; height: 50vh'); 
    document.getElementsByClassName(this.props.class)[0].append(div);

    this.refMap = L.map('map').setView([HARITA_DATA["KONUMLAR"][0]["ENLEM"], HARITA_DATA["KONUMLAR"][0]["BOYLAM"]], 13);
    
      L.tileLayer('http://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}', {

        maxZoom: 20,
        subdomains:['mt0','mt1','mt2','mt3']
      }).addTo(this.refMap);  
      
    
    for (let i = 0; i < HARITA_DATA["LISTE"].length; i++) {
      let markers = new L.MarkerClusterGroup({iconCreateFunction: function (cluster) {
        let newDiv0 = document.createElement("div");
        newDiv0.innerHTML = String(i);
        //console.log('mapComponent HARITA_DATA', HARITA_DATA["KONUMLAR"][i]["RENK"]);
        newDiv0.setAttribute("style", `background-color: ${HARITA_DATA.KONUMLAR[i].RENK}; padding-left: 9px; border-radius: 50%;`); 
  
        return L.divIcon({ 
            html: newDiv0,
            className: 'mycluster',
            iconSize: L.point(30, 30) // размер иконки
        });
        }
      }); 

      for (let j = 0; j < HARITA_DATA["KONUMLAR"].length; j++) {
        if (HARITA_DATA["KONUMLAR"][j]["RENK"] == HARITA_DATA["LISTE"][i]["RENK"]) {
          let newDiv = document.createElement("div");
          newDiv.innerHTML = String(j);
          console.log('mapComponent HARITA_DATA', HARITA_DATA["KONUMLAR"][i]["RENK"]);

          newDiv.setAttribute("style", `background-color: ${HARITA_DATA["KONUMLAR"][i]["RENK"]}; padding-left: 9px; border-radius: 50%;`); 
    
          markers.addLayer(
            L.marker([HARITA_DATA["KONUMLAR"][j]["ENLEM"], HARITA_DATA["KONUMLAR"][j]["BOYLAM"]], {icon: L.divIcon({className: 'my-div-icon', html: newDiv, iconSize: [30, 30]})})
          );
        }
      }
      this.refMarkers[i] = markers;
      this.refMap.addLayer(markers);
    }


    this.refСustomControl = L.control({
      position: 'topleft'
    });
    
    this.refСustomControl.onclick = function () {
      console.log('this.refСustomControl.onclick');
      this.changeMarkers();
    };

    let setStateOfThisObject = this.refColors;

    this.refСustomControl.onAdd = function () {
        let containerWithMarkerNames = L.DomUtil.create('div', 'containerWithMarkerNames');
        console.log('setStateOfThisObject', setStateOfThisObject);

        for (let i = 0; i < HARITA_DATA["LISTE"].length; i++) {
          let variableDiv = L.DomUtil.create("div", 'child_containerWithMarkerNames', containerWithMarkerNames);
          variableDiv.setAttribute("style", `color: ${HARITA_DATA["LISTE"][i]["RENK"]};`); 
          variableDiv.innerHTML = `${HARITA_DATA["LISTE"][i]["TEXT"]}`;
          variableDiv.onclick = function () {
            if (setStateOfThisObject.has(HARITA_DATA["LISTE"][i]["RENK"])) {
              setStateOfThisObject.delete(HARITA_DATA["LISTE"][i]["RENK"]);
            } else {
              setStateOfThisObject.add(HARITA_DATA["LISTE"][i]["RENK"]);
            }
            console.log('setStateOfThisObject', setStateOfThisObject);
            document.dispatchEvent(eventMapUpdate);

          };
        }

        return containerWithMarkerNames;
    };

    this.refСustomControl.addTo(this.refMap);

    this.refReturnMap = this.refMap?.toString();

  }

  componentWillUnmount() {
    this.refMap.remove();
    document.removeEventListener("MapUpdate", this.listenFunc);
    console.log('компонент MapComponent был размонтирован');

  }
  componentDidUpdate(){
    console.log('компонент обновился');
    console.log("this.refColors", this.refColors);
    //this.refСustomControl.remove();
    //console.log('this.refСustomControl', this.refСustomControl._container.childNodes);
    //console.log('this.refMarkers', this.refMarkers[0].options.icon.options.html.attributes[0].textContent.slice(18,25));//здесь номер цвета элемента
    //changeMarkers (this.refMarkers, this.refColors, this.refMap);
    this.refReturnMap = null;

  }

  listenFunc() {
    /*
    this.setState(function() {
      return {
        renderThisComponent: [0],
      };
    });
    */
    changeMarkers (this.refMarkers, this.refColors, this.refMap, HARITA_DATA);
  }


  render() {
      return this.refReturnMap;
  }
}
/*
Использование метода toString является хаком. В react возникает ошибка, если вернуть объект. 
Однако есть объект превратить в строку, то все получается. 
Примечательно, что ошибка возникает только при обновлении, а при первом рендеринге приблем нет. 
*/

export default Map;

//По какой то причине this.refMap в компоненте класса не проходит внутрь метода forEach
/*

Проблема с наличием нескольких экземпляров решена. Теперь карта обновляется без проблем. 
Однако проблема с присвоением css стилей маркерам остается. По какой то причине, если присвоить цвет 
иаркеру в js коде, то часть стилей в css файле перестает работать.
Вот почему размер шрифта и цвет текста по прежнему работают, а padding и border radius нет?

Проблема решилась после использования createElement и setAttribute, оказывается, что в L.divIcon можно 
вставлять также и уже созданный html элемент.
*/

/*
Задачи на 11.06.2024:

1. Нужно сделать контейнер с названиями и цветами в левом верхнем углу. 
Выполнено.

2. Нужно чтобы по клике на элемент контейнера появлялись и исчезали связанные с ним маркеры на 
карте. 

var layer = L.marker(latlng).addTo(map);
layer.addTo(map);
layer.remove();

L.DomUtil.create('div', 'info');

Как сделать удаление и добавление маркеров? 
Варианты: 
1. сделать все через обновление стейта. В таком случае при клике на табличку доюавляем значение 
в state, потом компонент обновляется, вся группа маркеров удаляется и создается в цикле заново, 
но уже отфильтрованным.

2. сделать все через addEventListener. В таком случае обновления компонента не будет происходить, 
но нужно будет удалить все прослушиватели событий. К тому же прослушиватели будут реагировать на все 
события click, или же надо будет делатьть кастомное событие. 

Через setState сделать вроде немного проще. 

добавляем к нужным элементам атрибут onClick
в callback изменяем state и добавляем в него данные, в этом случае цвет. 
Не получается положить в элемент функцию, которая обновляла бы компонент. 
Эта библиотека блокирует любое взаимодействие со всем, разве что кроме js.

делать через события тоже не хочется, так как делать это через addEventListener тоже скорее всего 
не получится. Нужно под каждый элемент сделать отдельную функцию, а потом при удалении элемента ее тоже 
удалить. К тому же элементов может быть хоть сотня, тогда производительность сильно упадет. 

Можно ли как нибудь скрыть от библиотеки хук?

Теперь нужно при обновлении компонента удалять старые элементы и создавать новые. 

Нет, проблема не решена, невозможно вставить метод react в любой элемент, который создается в 
leafleet. 

Однако можно попробовать вставить не функцию react, а создать и потом вызвать кастомное событие. 
На document поставить addEventListener и удалять его при размонтировании компонента. 
В случае срабатывания кастомного события вызывать перерисовку компонента. 


Возможно, нужно удалять экземпляр карты при каждом обновлении компонента. На данный момент 
в dom больше 10 div с id='map'.

18 + 7
*/

/*
Задачи на 12.06.2024:
1. сделать удаление и добавление маркеров. 
Как это сделать? 
Можно сделать функцию, в которую можно положить карту и массив с объектами-маркерами.
Далее с помощью метода remove удаляем объекты-маркеры и создаем их заново, но уже с фильтром. 

Почему нельзя просто удалить неподходящие маркеры? Потому что их еще нужно будет потом добавлять. 
по повторному клику на элемент с названием цвета. 


L.layerGroup([marker1, marker2])
    .addLayer(polyline)
    .addTo(map);
*/

/*

Способ обновления компонента из html элементов в карте. 
1. создаем кастомное событие.
2. создаем функцию listenFunc для обновления компонента.
3. создаем прослушиватель событий для document на кастомное событие и listenFunc.
4. Создаем ref. Значение обязательно должно быть ссылочным.
5. Создаем переменную, в которую записываем ссылку в ref.
6. навешиваем на нужные компоненты функцию по изменению значения переменной при событии click. 
    Нужно обратить внимание на способ изменения переменной, лучше использовать метод push.
7. при срабатывании события click вызываем кастомное событие и вызывается функция listenFunc, 
    которая обновляет компонент. 
8. в методе componentWillUnmount удаляем прослушиватель событий. 

Теперь при нажатии на нужный элемент изменяется переменная, а также изменяется значение ref, 
потому что значение ссылочное. Далее вызывается кастомное событие и срабатывает функция по 
обновлению компонента.

Примечание: необходимость такой сложной процедуры вызвана недостатком самой библиотеки leafleet. 
Данная библиотека не допускает срабатывания функций react в некоторых случаях. Вероятно это 
вызвано тем, что библиотека при создании элементов html использует что то вроде шаблона "фабрика", 
и создает объекты в своем коде на основе полученных данных. И react не импортирован в leafleet, 
это leafleet импортирована в проект react.

*/

/*
Обратите внимание на разницу в параметре «lyrs» в URL-адресе:

Hybrid: s,h;
Satellite: s;
Streets: m;
Terrain: p;

*/

/*
Возникает ошибка при использовании реальных данных. 
Данные есть, их структура идентична предыдущим данным, с которыми все работало. 
А вто сейчас возникают проблемы с чтением. 

Что может быть причиной?
Возможно в самой библиотеке leaflet много скрытых ограничений. Там может стоять строгая 
типизация и пропс может просто ее не проходить, хотя данные те же самые.
Это можно попробовать решить через использование глобальной переменной, в которую сразу после 
монтирования компонента можно записать данные из пропса. Однако в таком случае нужно 
следить чтобы такой компонент в одно время всегда был один, так как к этой глобальной переменной 
будут обращаться все существующие экземпляры компонентов. 

*/
