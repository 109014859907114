import { useEffect, useState, useInsertionEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import StoreRequest from '../formComponent/classStoreRequestForm';
import SecondForm from './secondForm';
import DinamikRaporRepository from './classDinamikRaporRepository';

import adress from '../../adress.js'

function inferenceOfSecondComponents (id, arr, indicatorOfCompletionOfFields) {
  if (arr?.length == undefined) {
    return;
  }
   
  let arr1 = [];
  for (let i = 0; i < arr.length; i++) {
    DinamikRaporRepository.secondFormInputsState[i] = arr[i].KOLON_DEGER ?? '';

    arr1.push(<SecondForm id={id} index={i} indicatorOfCompletionOfFields={indicatorOfCompletionOfFields} />);
  }
  console.log('middleComponent inferenceOfSecondComponents DinamikRaporRepository.secondFormInputsState', DinamikRaporRepository.secondFormInputsState);
  return arr1;
}

function fetchOfSecondComponents (id, currentValueOfTheDropdownList, token, setstatusUpdate, options, refForChangeThirdMiddle) {
  /*
  if (StoreRequest.store[id].thirdFormList != undefined && StoreRequest.store[id].thirdFormList != 0) {
    StoreRequest.store[id].thirdFormList = 0;
  }
  */

  let str = `${StoreRequest.store[id].dropDownList[currentValueOfTheDropdownList].SERVIS}?sessionId=${token}&formId=${StoreRequest.store[id].dropDownList[currentValueOfTheDropdownList].FORM_ID}&parametreler=${options}&kayitSayisi=-1`;
  
  //let str = String(StoreRequest.store[id].dropDownList[currentValueOfTheDropdownList].SERVIS) + '?sessionId=' + String(token) + '&formId=' + String(StoreRequest.store[id].dropDownList[currentValueOfTheDropdownList].FORM_ID) + '&parametreler=' + String(options) + '&kayitSayisi=-1';

  //str = JSON.stringify(str).replace('%22', '');
  //str = JSON.stringify(str);
  console.log('fetchOfSecondComponents str', str);
  
  //let str1 = 'https://creaapi.com/crealog/CrealogMaster.svc/GetWeHelpIsEmriKapamaGunluk/?sessionId=e17b5d5b-6b96-422a-86c3-3d206af9e28b&formId=4923&parametreler=ILK_TARIH;09.07.2024%2000:00|SON_TARIH;09.07.2024%2023:59:59|TUR;false|DEPARTMAN;false|STATU;false&kayitSayisi=-1';
  //let str2 = 'https://creaapi.com/crealog/CrealogMaster.svc/GetWeHelpIsEmriKapamaGunluk/?sessionId=e17b5d5b-6b96-422a-86c3-3d206af9e28b&formId=4923&parametreler=ILK_TARIH;10.07.2024%2000:00|SON_TARIH;11.07.2024%2023:59|TUR;false|DEPARTMAN;false|STATU;false&kayitSayisi=-1';

  fetch(str)
  .then(response => response.json())
  .then(function (json) {
    
    console.log("форма вызвала fetch");
    console.log("middleComponent fetchOfSecondComponents StoreRequest.store[id].secondFormList", StoreRequest.store[id]);

    StoreRequest.store[id].thirdFormList = json;
    console.log("middleComponent fetchOfSecondComponents StoreRequest.store[id].secondFormList", StoreRequest.store[id]);

    refForChangeThirdMiddle[0] = true;
    setstatusUpdate([0]);
  });
}

function middleComponent({id, currentValueOfTheDropdownList, setUpdate_preDinamikRapor, refForChangeThirdMiddle}) {
if (StoreRequest.store[id]?.secondFormList == undefined) {
  return; 
}

console.log('middleComponent StoreRequest.store[id]', StoreRequest.store[id]);

const token = useSelector((state) => state.tokenOfSession.token);
const [statusUpdate, setstatusUpdate] = useState([0]);
const refHeightSize = useRef(0);
const refPreviousValueOfTheDropdownList = useRef(0);
const indicatorOfCompletionOfFields = useRef(true);


const [heightSize, setheightSize] = useState('auto');
if (refPreviousValueOfTheDropdownList.current != currentValueOfTheDropdownList) {
  refPreviousValueOfTheDropdownList.current = currentValueOfTheDropdownList; 
  refHeightSize.current = 0;
  setheightSize('auto');
}

if (refHeightSize.current == 0) {
  queueMicrotask(function() {
    //console.log('middleComponent queueMicrotask document', document.getElementsByClassName('middleComponentConteiner')[0].offsetHeight);
    refHeightSize.current = document.getElementsByClassName('middleComponentConteiner')[0].offsetHeight + 5;
    setheightSize(`${refHeightSize.current}px`);
  });

  /*
  здесь queueMicrotask используется для нахождения высоты div со стилем auto и 
  повторного рендеринга с уже найденным значением. Если этого не сделать, то в первый раз 
  нельзя установить плавное закрытие div, он закроется мгновенно.
  */
} 


return <div>
  <div
  className='nameplate_middleComponent'
  onClick={function (event) {
    console.log('middleComponent refHeightSize.current', refHeightSize.current);
    if (heightSize != '0px') {
      setheightSize('0px');
    } else {
      setheightSize(`${refHeightSize.current}px`)
    }
  }} >
    <p className='header_nameplate_middleComponent'>ARAMA PARAMETLERİ</p>
    <p className='lastText_nameplate_middleComponent'>{heightSize == '0px' ? <span>&#9660;</span> : <span>&#9650;</span>} </p>
  </div>
  <div 
  className='middleComponentConteiner'
  onClick={ function (event) {
    console.log('middleComponent event', event);
    //event.target.children
    let sum = 0;
    for (let elem of event.target.children) {
      sum += elem.offsetHeight;
    }
    console.log('middleComponent sum', sum);
    }} style={{height: heightSize}} >
 {inferenceOfSecondComponents(id, StoreRequest.store[id].secondFormList, indicatorOfCompletionOfFields.current)}
  <button className='middleComponentButton' onClick={ function () {
    console.log('middleComponent button', StoreRequest.store[id].secondFormList);
    let str = '';
    for (let i = 0; i < StoreRequest.store[id].secondFormList.length; i++) {
      if(StoreRequest.store[id].secondFormList[i].BOS_OLABILIRMI == 0 && DinamikRaporRepository.secondFormInputsState[i] == '') {
        indicatorOfCompletionOfFields.current = false;
        setstatusUpdate([0]);
        return;
      }
      if(StoreRequest.store[id].secondFormList[i].BOS_OLABILIRMI == 1 && DinamikRaporRepository.secondFormInputsState[i] == '') {
        continue;
      }


      if(i == StoreRequest.store[id].secondFormList.length - 1) {
        str += `${StoreRequest.store[id].secondFormList[i].KOLON_ADI};${DinamikRaporRepository.secondFormInputsState[i]}`;
      } else {
        str += `${StoreRequest.store[id].secondFormList[i].KOLON_ADI};${DinamikRaporRepository.secondFormInputsState[i]}|`;
      }


    }
    console.log('middleComponent button', str, indicatorOfCompletionOfFields.current);
    if (!indicatorOfCompletionOfFields.current) {
      indicatorOfCompletionOfFields.current = true;
      console.log('middleComponent button', str);
      //setstatusUpdate([0]);
    }
    fetchOfSecondComponents (id, currentValueOfTheDropdownList, token, setUpdate_preDinamikRapor, str, refForChangeThirdMiddle);
  }} >Ara</button>
  
  </div>
</div>;
}

export default middleComponent;


//string.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&');

//\ea9d - похоже на юникод, вставлен с помощью css и after.


/*
нужно сделать контейнер, который будет окрываться и закрываться по клику. 
Однако желательно сделать окрытие и закрытие элемента плавным. 
Было бы хорошо сделать это через css стили. 
Не зная точный размер не удастся сделать плавное движение.

можно ли узнать высоту с помощью обращения к dom? 
Можно, но возникают проблемы. 
Во первых элемент появится в dom только после первого вызова функции. Если начать искать 
данный элемент в теле функции, то при первом вызове функции элемент не будет находиться. 
Это можно исправить если использовать обработчик событий (например onClick) и useRef, 
тогда поиск элемента будет осуществляться после встраивания элемента в dom.

Во вторых потребуется два рендера, так как чтобы элемент растянулся до нужных размеров, ему 
нужно дать значение auto а только потом посчитать высоту и заменить auto на конкретное значение. 
Нужно посмотреть на свойства dom.

Из родительского элемента можно просмотреть высоту всех дочерних элементов. Нужен простой счетчик и 
цикл. можно даже оптимизировать, записав результат в useRef.
Все получилось.

Возникает следующая проблема, из за того что в первый раз надо дать какое то значение для высоты, и 
это значение еще не рассчитано, высоте присваивается значение auto. А transition не работает с auto. 

Можно ли как нибудь посчитать значение? 
Можно попытаться найти элемент по классу, записать код в функцию, а функцию вызвать в style.
Не получается, div еще не создн в dome.
Нужно 2 рендера на 1 раз

Можно сделать queueMicrotask, в котором находим высоту у элемента, а потом кладем эту высоту в 
state и рендерим второй раз. Так как операция относительно быстрая и высота не меняется, то жто
не должно быть особо заметно.

*/

//<span>&#5167;</span> : <span>&#5169;</span>

/*
Теперь нужно собирать данные и делать запрос
Нужно сделать функцию для кнопки, в эту функцию положить все действия по отправке данных

Как сделать механизм обработки данных?
Нужно соединить все данные в строку и вставить ее в get запрос. 

Нужен id, по которому можно сделать сравнение, было ли изменение. Это нужно для 
изменения размера контейнера с полями. 
Нужно отслеживать изменение экрана.

Итак, нужно каким то образом отслеживать что было выбрано. Как это сделать?
Можно сделать специальный стейт в родительском компоненте, который будет хранить 
актуальное значение самого верхнего поля. Это не должно вызвать падение производительности, 
так как при любом изменении значения, все компоненты должны будут перерисовываться. 
Кроме того, там уже есть state, у которого единственная задача - это перерисовать родительский компонент. 
Можно просто заменить этот state. 

*/

/*
Задачи на 30/05/
1. Нужно отслеживать изменения данных и экрана, чтобы высота контейнера всегда 
была правильной. Высоту экрана решил не отслеживать, это будет делать адаптивная верстка.
Теперь при изменении данных, высота контейнера будет подстраиваться под новые данные. 

2. Нужно исправить ошибку, при которой при "горячем" обновлении перестает работать страница 
с новыми формами. Ошибка исправлена, но нужно теперь удалять данные после закрытия страницы. 
Как это сделать? Может к элемнтам верхнего меню доюавить функцию, которая будет очищать хранилище? 
Однако здесь проблема, новая страница использует то же хранилище, что и другие страницы. Невозможно 
удалить данные именно этой формы. Значит нужно использовать отдельное хранилище и удалять данные из него. 
После монтирования компонента preDinamikRapor данные из хранилища очищаются. 
Однако это почему то происходит только во второй раз. 
Компоненты удаляются и монтируются, однако очистки данных не происходит сразу. 
Очистка данных происходит строго во второй раз. 
Может это проблемы react?
Очень не хочется нарушать изоляцию и связывать элементы из меню с элементами данной формы.
Удалось сделать очистку данных с помощью useRef. Оказалось, что проблема действительно была 
в хуках react.

3. нужно сделать функцию по считыванию данных из форм и отправке запроса на сервер.
Нужно проверить обязательность заполнения поля, это свойство boş oiabilir, если 0, то 
обязательно заполнение, а если 1, то нет.
Оба используемых хранилища синхронизированы в функции inferenceOfSecondComponents, 
а значит их можно просто перебрать в цикле.
Строка с параметрами делается, однако теперь нужно указать пользователю какие поля обязательны для 
заполнения и запретить отправлять незаполненные запросы. 
Нужно сделать useRef, который менялся бы при попытке отправки неполных данных, 
далее этот ref попадал бы в дочерний компонент, в дочернем компоненте поля в случае 
boş olabilirmi = 0 проводилась бы проверка на наличие значение и присваивался бы класс с нужными 
стилями. 

Возникает проблема с календарем, по какой то причине идет неправильная проверка, несмотря на 
то, что поле пустое, проверка показывает, что в нем есть значение. 
Может календарь не обновляет компонент?
Обновление происходит, однако оно происходит с опозданием. 
Проблема решена.


По какой то причине indicatorOfCompletionOfFields.current при возвращении в положение true 
и обновлении компонента, не возвращает нормальные стили для полей. 
Проблема решена

*/

/*

https://creaapi.com/crealog/CrealogMaster.svc/GetWeHelpIsEmriKapamaHaftalik
/?sessionId=ede0e037-5a5c-4b21-8c88-dee866e57a52&formId=4922&
parametreler=ILK_TARIH;30.05.2024%2000:00|SON_TARIH;30.05.2024%2023:59:59&kayitSayisi=-1
*/

//fetch(`${StoreRequest.store[id].dropDownList[currentValueOfTheDropdownList].SERVIS}?sessionId=${token}&formId=${StoreRequest.store[id].dropDownList[currentValueOfTheDropdownList].FORM_ID}&parametreler=${options}&kayitSayisi=-1`)

//https://creaapi.com/crealog/CrealogMaster.svc/GetWeHelpIsEmriKapamaGunluk/?sessionId=e17b5d5b-6b96-422a-86c3-3d206af9e28b&formId=4923&parametreler=ILK_TARIH;10.07.2024 00:00|SON_TARIH;11.07.2024 23:59|TUR;false|DEPARTMAN;false|STATU;false&kayitSayisi=-1

/*
Get запрос работает нестабильно, в одних случаях отправляется, а в других нет. Причина этого не ясна, 
запросы не отличаются сильно. Замечено, что проблема возникает при изменении даты в календаре. 

В SON_TARIH в правильном варианте указаны секунды, а в неправильном варианте нет. 
Это необязательно, все работает и без секунд

Проблема возникает рандомно при изменении дат. 
Нужно изменить обе даты сразу в большую сторону.
Проблема возникает в случае увеличения ilk bahar. 
Да, проблема определенно возникает при установлении начальной даты позже, чем сегодня.
Но почему?

*/

/*
нужно сделать так, чтобы данные для 3 компонента удалялись, после чего происходило бы 
обновление компонента. 
Если это сделать перед запросом на сервер, то обновление компонентов будет уже после 
перезаписи данных в хранилище, а значить удаление данных просто не доходит до нужного компонента. 

Если это сделать во время изменения полей, то нужно в эти поля передавать state для обновления всех 
компонентов, и тогда все будет перерисовываться на каждое действие. 

В чем возникает проблема? Контейнер в 3 компоненте не успевает скорректировать свою высоту. 
Нужно чтобы компонент монтировался снова, или же индекс выбранного варианта лоджен измениться. 

Индекс влияет на выбор данных во втором компоненте для третьего компонента. 
Проверено, что индекс изменить нельзя. При нескольких нажатиях на кнопку ara возникает ошибка. 

Каким еще образом можно решить эту проблему? 
Если 3 компонент узнает, что была нажата кнопка ara или изменились данные в хранилище, то 
тогда возможно изменить размер контейнера под новые элементы. 



*/


