import { useEffect, useState, useInsertionEffect, useRef } from 'react';

//import response from './response';
import DinamikRaporTable from './DinamikRaporTable';
import WeHelpDiagramOne from '../../Dashboard/WeHelp/WeHelpDiagramOne1';
import WeHelpDiagramMultiple from '../../Dashboard/WeHelp/WeHelpDiagramMultiple1';
import WeHelpDiagramPie from '../../Dashboard/WeHelp/WeHelpDiagramPie1';
import SortDiagramLine from '../../Dashboard/WeHelp/multypleDiagramLine/SortDiagramLine';
import WeHelpDiagramLine1 from '../../Dashboard/WeHelp/WeHelpDiagramLine1';
import WeHelpDiagramSmoothLine from '../../Dashboard/WeHelp/WeHelpDiagramSmoothLine';

import MapComponent from './MapComponent';


const color_1 = '#34C6CD';
const color_2 = '#FF3500';
const color_3 = '#B1F100';
const color_4 = '#FFF500';
const color_5 = '#7908AA';
const color_6 = '#FF7340';
const color_7 = '#FFAB00';
const color_8 = '#009E66';
const color_9 = '#AA66CC';

const colorArr = [color_1, color_2, color_3, color_4, color_5, color_6, color_7, color_8, color_9];

const diagramStyle = {
  height: 'auto',
  marginBottom: '0px',
  paddingBottom: '53px'
};

function setGrafikBarOne (response, componentNumber) {
  let arr = [];
  let maxY = 0;
  for (let i = 0;  i < response["DATA"][componentNumber]["GRAFIK"]["series"].length; i++) {
    arr.push({
      "value": response["DATA"][componentNumber]["GRAFIK"]["series"][i]["data"][0],
      "color": colorArr[i],
      "nameColumn": response["DATA"][componentNumber]["GRAFIK"]["series"][i]["name"],
    });
    if (maxY < response["DATA"][componentNumber]["GRAFIK"]["series"][i]["data"][0]) {
      maxY = response["DATA"][componentNumber]["GRAFIK"]["series"][i]["data"][0];
    }
  }
  
  return <div className='diagramWrapping oneDimensionalDiagram' style={diagramStyle}>
    <WeHelpDiagramOne data={arr} maxY={maxY} />
  </div>
}

function setGrafikBarTwo (response, componentNumber) {
  let arr = [];
  let signature = [];
  let maxY = 0;
  for (let i = 0;  i < response["DATA"][componentNumber]["GRAFIK"]["categories2"].length; i++) {
    let arr1 = [];
    for (let j = 0; j < response["DATA"][componentNumber]["GRAFIK"]["series"].length; j++) {
      console.log('MiddleComponentForThirdForm setGrafikBarTwo arr', response["DATA"][componentNumber]["GRAFIK"]["series"][j]["data"][i]);

      arr1.push({
        "value": response["DATA"][componentNumber]["GRAFIK"]["series"][j]["data"][i],
        "color": colorArr[j],
        "nameColumn": response["DATA"][componentNumber]["GRAFIK"]["series"][j]["name"],
      });
      if (maxY < response["DATA"][componentNumber]["GRAFIK"]["series"][j]["data"][i]) {
        maxY = response["DATA"][componentNumber]["GRAFIK"]["series"][j]["data"][i];
      }
    }
    arr.push(arr1);
  }
  for (let elem of arr[0]) {
    signature.push({
      "color": elem["color"],
      "nameColumn": elem["nameColumn"],
    });
  }
  console.log('MiddleComponentForThirdForm setGrafikBarTwo arr', arr);
  return <div className='diagramWrapping multidimensionalDiagram' style={diagramStyle}>
    <WeHelpDiagramMultiple data={arr} signature={signature} nameColumnGroup={response["DATA"][componentNumber]["GRAFIK"]["categories2"]} maxY={maxY} />
  </div>
}

function setGrafikPie (response, componentNumber) {
  let arr = [];
  let maxY = 0;
  let summOfValues = 0;
  for (let i = 0;  i < response["DATA"][componentNumber]["GRAFIK"]["series"].length; i++) {
    arr.push({
      displayElement: response["DATA"][componentNumber]["GRAFIK"]["series"][i]["name"],
      rate: response["DATA"][componentNumber]["GRAFIK"]["series"][i]["data"][0],
    });
    if (maxY < response["DATA"][componentNumber]["GRAFIK"]["series"][i]["data"][0]) {
      maxY = response["DATA"][componentNumber]["GRAFIK"]["series"][i]["data"][0];
    }
    summOfValues += response["DATA"][componentNumber]["GRAFIK"]["series"][i]["data"][0];
  }

  console.log('MiddleComponentForThirdForm setGrafikPie arr', arr);

  return <div className='diagramWrapping' style={diagramStyle}>
    <WeHelpDiagramPie data={arr} maxY={maxY} onePercent={summOfValues/100} />
  </div>
}

function setGrafikLine1 (response, componentNumber) {
  let arr = [];
  arr[0] = colorArr;
  let arrNamesVariable = [];
  let maxY = 0;
  for (let i = 0; i < response["DATA"][componentNumber]["GRAFIK"]["series"].length; i++) {
    arr.push(response["DATA"][componentNumber]["GRAFIK"]["series"][i]["data"]);
    arrNamesVariable.push(response["DATA"][componentNumber]["GRAFIK"]["series"][i]["name"]);

    let maxVariable = Math.max(...response["DATA"][componentNumber]["GRAFIK"]["series"][i]["data"]);
    if (maxY < maxVariable) {
      maxY = maxVariable;
    }
  }
  arr.push(arrNamesVariable);
  arr.push(response["DATA"][componentNumber]["GRAFIK"]["categories2"]);

  return <div className='diagramWrapping lineDiagram' style={diagramStyle}>
    <WeHelpDiagramLine1 data={arr} maxY={maxY} />
  </div>;
}

function setGrafikLine2 (response, componentNumber) {

  return <div className='diagramWrapping_for_multipleLine' style={diagramStyle}> 
  <WeHelpDiagramSmoothLine dates={response["DATA"][componentNumber]["GRAFIK"]["categories2"]} data={response["DATA"][componentNumber]["GRAFIK"]["series"][0]["data"]} 
  name={response["DATA"][componentNumber]["GRAFIK"]["series"][0]["name"]} color={colorArr[0]} />
  </div>;
}

function setGrafikLines (response, componentNumber) {

  return <SortDiagramLine signature={response["DATA"][componentNumber]["GRAFIK"]["categories2"]} data={response["DATA"][componentNumber]["GRAFIK"]["series"]} />;

}



function MiddleComponentForThirdForm({id, currentValueOfTheDropdownList, response, refForChangeThirdMiddle}) {

  //const refHeightSize = useRef(0);
  //const refPreviousValueOfTheDropdownList = useRef(0);
  const [componentNumber, setcomponentNumber] = useState(0);
  const refSignboard = useRef(0);
  const refFlagSignboard = useRef([false, 0, 0]);

  /*
  const [heightSize, setheightSize] = useState('auto');
  if (refPreviousValueOfTheDropdownList.current != currentValueOfTheDropdownList || refForChangeThirdMiddle[0]) {
    refPreviousValueOfTheDropdownList.current = currentValueOfTheDropdownList; 
    refHeightSize.current = 0;
    refForChangeThirdMiddle[0] = false;
    setheightSize('auto');
  }
  
  if (refHeightSize.current == 0) {
    queueMicrotask(function() {
      console.log('middleComponent queueMicrotask document', document.getElementsByClassName('middleComponentConteiner')[0].offsetHeight);
      refHeightSize.current = document.getElementsByClassName('MiddleComponentForThirdForm')[0].offsetHeight + 5;
      setheightSize(`${refHeightSize.current}px`);
    
    // при нажатии кнопки ara размер контейнера не увеличивается
    });
  
    /*
    здесь queueMicrotask используется для нахождения высоты div со стилем auto и 
    повторного рендеринга с уже найденным значением. Если этого не сделать, то в первый раз 
    нельзя установить плавное закрытие div, он закроется мгновенно.
    */
  //} 
  


console.log('response', response);

let componentWithAdditionalInformation;
if (response != undefined) {
  switch (response["DATA"][componentNumber]["ADI"]){
    case "Grafik Bar 1":
      componentWithAdditionalInformation = setGrafikBarOne (response, componentNumber);
    break;
    case "Grafik Bar 2":
      componentWithAdditionalInformation = setGrafikBarTwo (response, componentNumber);
    break;
    case "Grafik Pie":
      componentWithAdditionalInformation = setGrafikPie (response, componentNumber);
    break;
    case "Grafik Line 1":
      componentWithAdditionalInformation = setGrafikLine1 (response, componentNumber);
    break;
    case "Grafik Line 2":
      componentWithAdditionalInformation = setGrafikLine2 (response, componentNumber);
    break;
    case "Grafik Line 3":
      componentWithAdditionalInformation = setGrafikLines (response, componentNumber);
    break;
    case "Tablo":
      let dataJson = JSON.parse(response["DATA"][componentNumber].DATA);
      componentWithAdditionalInformation = <DinamikRaporTable data={dataJson} columnsTable={Object.keys(dataJson[0])} id={id} divFlag={false} currentValueOfTheDropdownList={currentValueOfTheDropdownList} refForChangeThirdMiddle={refForChangeThirdMiddle} />;
    break;
    case "Harita Liste":
      componentWithAdditionalInformation =   <>
      <div className='map'></div>
      <MapComponent class={'map'} HARITA_DATA={JSON.parse(response.DATA[componentNumber].HARITA_DATA)} />
      </>;
    break;
  }
}


return <div>
  <div className='signboard_middleComponentForThirdForm' ref={refSignboard} 
  onMouseDown={(event) => {
    console.log('signboard_middleComponentForThirdForm', refSignboard.current.scrollLeft, event.clientX);
    refFlagSignboard.current = [true, event.clientX, refSignboard.current.scrollLeft];
    }}
  onMouseUp={(event) => {
    console.log('signboard_middleComponentForThirdForm', refFlagSignboard.current);
    refFlagSignboard.current = [false, 0, 0];
  }}
  onMouseLeave={(event) => {
    if(refFlagSignboard.current[0]) {
      console.log('signboard_middleComponentForThirdForm', refFlagSignboard.current);
      refFlagSignboard.current = [false, 0, 0];
    }
  }}
  onMouseMove={(event) => {
    //console.log('signboard_middleComponentForThirdForm', refSignboard.current.scrollLeft, event.clientX);
    //refFlagSignboard.current = [true, event.clientX];
    
    if (refFlagSignboard.current[0]) {
      refSignboard.current.scrollLeft =  refFlagSignboard.current[1] - event.clientX + refFlagSignboard.current[2];
    }
  
  }}
    > 
    {response["OZET"].map(function(item) {
      return <div className='sticker'>
        <p className='stickerHeader'> {item["BASLIK"]} </p>
        <p className='stickerText'> {item["DEGER"]} </p>
      </div>
    })}
  </div>

    {
      response.DASHBOARS.map(function (item) {
        let dashboardsJson = JSON.parse(item.DATA);
        return <DinamikRaporTable data={dashboardsJson} columnsTable={Object.keys(dashboardsJson[0])} id={id} divFlag={true} currentValueOfTheDropdownList={currentValueOfTheDropdownList} refForChangeThirdMiddle={refForChangeThirdMiddle} name={item.ADI} />

      })
    }
  <div className='signboard_middleComponentForThirdForm' > 
    {response["DATA"].map(function(item, index) {
      return <div className='stickerAlt' 
      style={{color: componentNumber == index? '#4fa2fc' : '#383839'}}
      onClick={()=> setcomponentNumber(index)}>
        <p className='stickerHeader'> {item.ADI} </p>
      </div>
    })}
  </div>
  {componentWithAdditionalInformation}
</div>;
}

export default MiddleComponentForThirdForm;



//\ea9d - похоже на юникод, вставлен с помощью css и after.

/*
данные для самых верхних блоков находится в response["OZET"]. Данные блоки 
не имеют какого либо функционала, они только дают информацию. 




*/

/*
Задачи на 31.05:
1. сделать карусель, туда положить блоки с текстом. Количество блоков и 
текст в них нужно взять из переменной response;
Выполнено.

2. нужно сделать карусель, наподобие altForm. 
Как сделать чтобы они

3. попробовать вставить таблицу. Подумать как это сделать.
Это тоже сделано, однако нет возможности отследить изменение размера
таблицы. Невозможно удалить возможность выбора количества строк.
Если взять большую таблицу на несколько сотен строк, то при уменьшении 
количества строк останется большой пробел.

*/

/*
  Задачи на 03/06
1. Нужно сделать механизм, который будет определять какой именно компонент будет выведен далее. 
Как это сделать? 
Можно сделать еще один компонент регулятор, можно сделать функцию, 
а можно сделать конструкцию if. 
Возможно это лучше вывести в отдельную функцию.  

2. нужно правильно разложить данные для графика. 
В ответе с сервера данные расположены отдельно несколько цифр для каждого наименования, 
а нужно распределить цифры и к каждому присоединить наименование.


*/

/*
Задачи на 07/06/2024: 
1. нужно сделать еще один файл с еще одной диаграммой. 
2. нужно добавить ту таблицу, что уже есть, еще один раз рядом с графиками. 
После всего этого остается только сделать карту. 



*/

/*
  <div
  className='nameplate_MiddleComponentForThirdForm'
  onClick={function (event) {
    if (heightSize != '0px') {
      setheightSize('0px');
    } else {
      setheightSize(`${refHeightSize.current}px`)
    }
  }} >
    <p className='header_nameplate_MiddleComponentForThirdForm'> {response["DASHBOARS"][0].ADI} </p>
    <p className='lastText_nameplate_MiddleComponentForThirdForm'>{heightSize == '0px' ? <span>&#9660;</span> : <span>&#9650;</span>} </p>
  </div>
*/

/*
Нужно сделать еще одну вещь. Нужно чтобы можно было двигать контейнер с плашками влево-вправо 
просто зажимая левую кнопку мыши и двигая мышь. 
Как это сделать? 

С помощью container.scrollLeft = 300; можно переместить контейнер на указанное количество пикселей. 

Теперь нужно узнать на какое количество пикселей переместить.
Нажатие кнопки можно отследить с помощью onMouseDown. 
Можно сделать специальный флаг, который при событии onMouseDown будет принимать значение 
true, а при событии onMouseUp значение false. 
В таком случае с помощью события onMouseMove можно отследить перемещение мыши с зажатой клавишей. 

Если измерить отступ слева при событии onMouseDown, то при срабатывании onMouseMove можно заново 
считывать отступ мыши и изменять положение контейнера. 

Еще остается открытым вопрос как отследить накхождение мыши над нужным элементом.

Как правилно посчитать? 

*/
