import { useEffect, useState, useInsertionEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import StoreRequest from '../formComponent/classStoreRequestForm';

import adress from '../../adress.js'

const flagTrueFalse = <svg className="svgTrue" viewBox="12 17 32 32">
<path d="M30 19l-9 9-3-3-2 2 5 5 11-11z"></path>
</svg>;




let formClassName = 'wrappingFormTable';

function fetchDropDownList (data, index, token, id, setcurrentValueOfTheDropdownList) {
  console.log('dropDownList fetchDropDownList', data[index].SERVIS);

  if (StoreRequest.store[id].thirdFormList != undefined && StoreRequest.store[id].thirdFormList != 0) {
    StoreRequest.store[id].thirdFormList = 0;
  }

  fetch(`https://creaapi.com/${adress[0]}/CrealogMaster.svc/GetRaporParemetreler/?sessionId=${token}&formId=${data[index].FORM_ID}`)

  .then(response => response.json())
  .then(function (json) {
    
    console.log("форма вызвала fetch");

    StoreRequest.store[id].secondFormList = json.GetRaporParemetrelerResult;

    console.log('fetch', StoreRequest.store[id]);
    console.log('fetch data[index]', data[index].FORM_ID);
    //setcurrentValueOfTheDropdownList(data[index].FORM_ID);
    setcurrentValueOfTheDropdownList([index]);
  });
}

function dropDownList({id, data, setcurrentValueOfTheDropdownList}) {


const token = useSelector((state) => state.tokenOfSession.token);
const [statusUpdate_FormTable, setstatusUpdate_FormTable] = useState(false);
const dataCollection = useRef(0);
if (dataCollection.current == 0) {
  dataCollection.current = {};
  for (let i = 0; i < data.length; i++) {
    dataCollection.current[data[i].ADI] = i;
  }
}
console.log('dropDownList dataCollection.current', dataCollection.current);


const [templateForPopUp, settemplateForPopUp] = useState('');
const regexpForPopUp = new RegExp(`${templateForPopUp.replace(/\|/g, '\\|')}`, 'i');

let flagFormTable = templateForPopUp == '';

console.log('dropDownList data', data);


let popUpWindow = <div className={`popUpWindow_FormTable`}>
  {templateForPopUp == '' && data.map(item => <div 
  onMouseDown={function(event) {
    fetchDropDownList (data, dataCollection.current[item.ADI], token, id, setcurrentValueOfTheDropdownList);
    settemplateForPopUp(item.ADI);
  }}>{item.ADI}</div>)}
  
  {templateForPopUp != '' && data.filter(function (item) {
    let v = regexpForPopUp.test((item.ADI));
    regexpForPopUp.lastIndex = 0;
    console.log('dropDownList popUpWindow v', regexpForPopUp, v, '-', item.ADI);
    return v;  
  }).map(item => <div 
  onMouseDown={function(event) {
    //console.log('dropDownList popUpWindow templateForPopUp, item.ADI', templateForPopUp == item.ADI);
    if (templateForPopUp != item.ADI) {
      fetchDropDownList (data, dataCollection.current[item.ADI], token, id, setcurrentValueOfTheDropdownList);
      settemplateForPopUp(item.ADI); 
    }
  }}>{item.ADI}</div>)}
</div>;


let form = <div className={formClassName} style={{marginLeft: '10vw'}} >
  <p className={`${ flagFormTable ? 'activeparagraphFormTable' : 'inactiveparagraphFormTable'}`} > Rapor Seçinis </p>
  <input placeholder={'Kayıtlı Şablonlar'} className={`${!flagFormTable ? 'activeinputFormTable' : 'inactiveinputFormTable'}`} value={templateForPopUp} 
    onChange={function(event) {
      
      settemplateForPopUp (event.target.value);
    }} 
    onKeyDown={ function (event) {
      if (event.keyCode == 13 && dataCollection.current[event.target.value] != undefined) {
        fetchDropDownList (data, dataCollection.current[event.target.value], token, id, setcurrentValueOfTheDropdownList);
      }
  }}/>
  {popUpWindow}
</div>;





return <>
  
  {form}

  

</>;
}

export default dropDownList;


//string.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&');

/*
Нужно делать запрос по указанному в data адресу. 
Этот запрос чаще всего будет делаться по нажатию на элемент выпадабщего списка. 
Однако нужно сделать такую же функцию и на input. Однако в таком случае нужно сделать много 
проверок. 
Нужно: проверить есть ли такое значение в data, чтобы не было ложных срабатываний. 
Как это сделать? 
Если бы была коллекция с ключами в виде имени и значением в виде индекса, то 
сложность была бы 1. А записать эту коллекцию в useRef, чтобы создавалась 1 раз. 

Как сделать так, чтобы запрос на сервер отправлялся только в случае изменения строки?
Просто проверить наличие свойства secondFormList нельзя, так как под этим ключом могут 
быть разные данные. 
Проверить равенство данных в input и в элементе?
*/
