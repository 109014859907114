import { useState, useRef, useEffect } from 'react';
import './header1.css';
import './headerAdaptive.css';
import { useDispatch, useSelector } from 'react-redux';
//import Menu from './menu.js';
//import Menu_for_other_brausers from './menu_for_other_brausers.js';
import TopMenu from './topMenu.js';
import UserMenu from './userMenu.js';
import logoc from "../resources/logoc.png"
import sapLogo from "../resources/sapLogo.png"
import adress from '../adress.js'


import Menu from './menu1.js';


function Header() {

const nameOfPage = useSelector((state) => state.changingPageName.pageName);

const token = useSelector((state) => state.tokenOfSession.token);
const userName = useSelector((state) => state.userAuthentication.userName);



const [dataOfMenu, setdataOfMenu] = useState({});
const [arrOfTopMenu, setarrOfTopMenu] = useState(['initKey', 0]);
const [widthOfMenu, setwidthOfMenu] = useState(0);
const [nameOfPageState, setnameOfPageState] = useState(nameOfPage);
const [rerenderState, setrerenderState] = useState([0]);
let nameOfPageRef = useRef(0);
let menuWidth = useRef(0);

useEffect(() => {
  console.log('header смонтирован');
  nameOfPageRef.current = nameOfPage;
  menuWidth.current = 0;
  console.log('header nameOfPage', nameOfPageRef.current);
}, []); 
/*
if (nameOfPageRef.current != nameOfPage && menuWidth.current != 0 && nameOfPage != 'homePage') {
  nameOfPageRef.current = nameOfPage;
  menuWidth.current = 0;
  setrerenderState([0]);
  console.log('header nameOfPage', nameOfPage);
  console.log('header условие закрытия меню работает');
}
*/

console.log('header compare', nameOfPageRef.current != nameOfPage && menuWidth.current != 0 && nameOfPageRef.current != 'authorizationPage');
console.log('header compare nameOfPageRef.current != nameOfPage', nameOfPageRef.current != nameOfPage);
console.log('header compare menuWidth.current != 0', menuWidth.current != 0);
console.log('header compare menuWidth.current', menuWidth.current);
console.log('header compare nameOfPageRef.current != authorizationPage', nameOfPageRef.current != 'authorizationPage');





if (nameOfPageRef.current != nameOfPage && menuWidth.current != 0 && nameOfPageRef.current != 'authorizationPage') {
  console.log('header menuWidth.current', menuWidth.current);
  nameOfPageRef.current = nameOfPage;
  menuWidth.current = 0;
  setrerenderState([0]);
  console.log('header nameOfPage', nameOfPage);
  console.log('header условие закрытия меню работает');
  console.log('header menuWidth.current', menuWidth.current);
} else if (nameOfPageRef.current != nameOfPage && (nameOfPageRef.current == 'authorizationPage' || nameOfPage == 'homePage')) {
  nameOfPageRef.current = nameOfPage;
}

/*
useEffect(() => {
  console.log('header смонтирован');
  nameOfPageRef.current = nameOfPage;
  console.log('header nameOfPage', nameOfPageRef.current);
}, []); 

if (nameOfPageRef.current != nameOfPage && widthOfMenu != 0) {
  
  setwidthOfMenu(0);
  nameOfPageRef.current = nameOfPage;
  console.log('header nameOfPage', nameOfPageRef.current);
}
*/
/*
if (nameOfPageState != nameOfPage && widthOfMenu != 0) {
  setnameOfPageState(nameOfPage);
  setwidthOfMenu(0);
  console.log('header nameOfPage', nameOfPage);
}
*/
/*
useEffect(() => {
  console.log('header смонтирован');
  nameOfPageRef.current = nameOfPage;
  console.log('header nameOfPage', nameOfPageRef.current);
}, []); 

console.log('nameOfPageRef.current != nameOfPage && widthOfMenu != 0', nameOfPageRef.current != nameOfPage && widthOfMenu != 0);

if (nameOfPageRef.current != nameOfPage && widthOfMenu != 0) {
  nameOfPageRef.current = nameOfPage;
  setwidthOfMenu(0);
  console.log('header nameOfPage', nameOfPage);
}
*/

/*
Условие срабатывает когда надо. По каким то причинам setwidthOfMenu(0) не работает.
Почему то при использовании хука все работает гораздо лучше, чем при использовании ref. 

Можно попробовать использовать state с пустым массивом, чтобы заставлять компонент обновляться,
а также ref 
*/

const variable = arrOfTopMenu;
if (token != 0) {
  if (Object.keys(dataOfMenu).length == 0) {
  //if (dataOfMenu == 0) {
    //fetch(`https://creaapi.com/crealog/CrealogMaster.svc/GetModullerFormlar/?sessionId=${token}&mobil=0`)
    //fetch(`https://creaapi.com/crealogKlon/CrealogMaster.svc/GetModullerFormlar/?sessionId=${token}&mobil=0`)
    fetch(`https://creaapi.com/${adress[0]}/CrealogMaster.svc/GetModullerFormlar/?sessionId=${token}&mobil=0`)
    .then(response => response.json())
    .then(function (json) {
      let variable = json;
      console.log('header fetch', variable);
      setdataOfMenu(variable);
    })
  }
}

let isChrom = false;
let userAgent = navigator.userAgent.toLowerCase();
if (/chrome/.test(userAgent) && !/chromium/.test(userAgent) && !/edg/.test(userAgent)) {
  isChrom = true;
}

const menuSimbol = <div className='menuSimbol' onClick={function() {
  console.log('nameOfPageState', nameOfPageState);
  console.log('widthOfMenu', widthOfMenu);
  if (menuWidth.current == 0) {
    console.log('условие if срабатывает');
    menuWidth.current = 20;
    setrerenderState([0]);
  } else {
    console.log('условие else срабатывает');
    menuWidth.current = 0;
    setrerenderState([0]);
  }
}} style={{marginLeft: `${1 + menuWidth.current}vw`}}>&#9776;</div>;

const hightLogo = <div className='hight_logo'>
<img src={logoc} alt="C" className='logoc'/> 
<span>realog</span>
<img src={sapLogo} alt="sap" className='sapLogo'/> </div>;

const headerTop = <header className={'top_header'}>
  {nameOfPage != 'authorizationPage' ? menuSimbol  : hightLogo}
  {userName != 0 && <UserMenu userName={userName} />}
</header>
/*
const headerSide = <header className={'side_header'} style={{width: `${menuWidth.current}vw`}}>
  {hightLogo}
  {isChrom == true ? <Menu dataOfMenu={dataOfMenu} setarrOfTopMenu={setarrOfTopMenu} /> : <Menu_for_other_brausers dataOfMenu={dataOfMenu} setarrOfTopMenu={setarrOfTopMenu} />} 
</header>
*/
const headerSide = <header className={'side_header'} style={{width: `${menuWidth.current}vw`}}>
  {hightLogo}
  <Menu dataOfMenu={dataOfMenu} setarrOfTopMenu={setarrOfTopMenu} />
</header>;
  return (
  <>
      {headerTop}
      {nameOfPage != 'authorizationPage' && <TopMenu arrOfTopMenu={arrOfTopMenu} setarrOfTopMenu={setarrOfTopMenu}/>}
      {nameOfPage != 'authorizationPage' && headerSide}
  </>

  );
}

export default Header;
