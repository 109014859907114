
import { useMemo, useState, useRef} from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table';

import { MRT_Localization_TR } from 'material-react-table/locales/tr';

import { Box } from '@mui/material';





function DinamikRaporTable ({data, columnsTable, id, divFlag, currentValueOfTheDropdownList, refForChangeThirdMiddle, name}) {
  const refHeightSize = useRef(0);
  const refPreviousValueOfTheDropdownList = useRef(0);
  
  const [heightSize, setheightSize] = useState('auto');
  if (refPreviousValueOfTheDropdownList.current != currentValueOfTheDropdownList || refForChangeThirdMiddle[0]) {
    refPreviousValueOfTheDropdownList.current = currentValueOfTheDropdownList; 
    refHeightSize.current = 0;
    refForChangeThirdMiddle[0] = false;
    setheightSize('auto');
  }
  
  if (refHeightSize.current == 0) {
    queueMicrotask(function() {
      console.log('middleComponent queueMicrotask document', document.getElementsByClassName('middleComponentConteiner')[0].offsetHeight);
      refHeightSize.current = document.getElementsByClassName('MiddleComponentForThirdForm')[0].offsetHeight + 5;
      setheightSize(`${refHeightSize.current}px`);
    
    // при нажатии кнопки ara размер контейнера не увеличивается
    });
  
    /*
    здесь queueMicrotask используется для нахождения высоты div со стилем auto и 
    повторного рендеринга с уже найденным значением. Если этого не сделать, то в первый раз 
    нельзя установить плавное закрытие div, он закроется мгновенно.
    */
  } 
let tableDataVariable = columnsTable.map(function (item) {
  return {
    accessorKey: item, //access nested data with dot notation
    header: item,
  }
});

const columns = useMemo(
  () => tableDataVariable,
  [tableDataVariable],
);
console.log('DinamikRaporTable data', data);

const table = useMaterialReactTable({
  columns,
  data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
  localization: MRT_Localization_TR,
  enableDensityToggle: false,
  paginateExpandedRows: false,
  initialState: { pagination : { pageSize : 100 }, density: 'comfortable' },
});

let tableReturn;
if (divFlag) {
  tableReturn = <>
  <div
  className='nameplate_MiddleComponentForThirdForm'
  onClick={function (event) {
    if (heightSize != '0px') {
      setheightSize('0px');
    } else {
      setheightSize(`${refHeightSize.current}px`)
    }
  }} >
    <p className='header_nameplate_MiddleComponentForThirdForm'> {name} </p>
    <p className='lastText_nameplate_MiddleComponentForThirdForm'>{heightSize == '0px' ? <span>&#9660;</span> : <span>&#9650;</span>} </p>
  </div>
  
  <div className='MiddleComponentForThirdForm' style={{height: heightSize}}>
    <Box
    sx={{
      width: '94vw',
      marginLeft: '1vw',
      marginBottom: '15px',
      '& .highlightedLine:hover td': {
        backgroundColor: '#c8c7cc',
      }
    }} className={`${id}`} >
      <MaterialReactTable table={table} />
    </Box>
  </div>
  </>
} else {
  tableReturn = <Box
  sx={{
    width: '94vw',
    marginLeft: '1vw',
    marginBottom: '15px',
    '& .highlightedLine:hover td': {
      backgroundColor: '#c8c7cc',
    }
  }} className={`${id}`} >
    <MaterialReactTable table={table} />
  </Box>
}

  return tableReturn;
};

export default DinamikRaporTable;

